import { Button, Result } from 'antd';
import React from 'react'
import { FlexContainer } from '../styles/globalStyle';
import history from '../history';
const NotFound = () => {
    return ( 
        <FlexContainer>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button onClick={()=> history.push('/') } type="primary">Back Home</Button>}
                style={{alignSelf: 'center'}}
            />
        </FlexContainer>
     );
}
 
export default NotFound;