import React, { useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import { SubHeaderText } from "../../styles/globalStyle";
import MFS from "./mfs";
import Banking from "./banking";

const { TabPane } = Tabs;
const AddPaymentMethod = ({ refresh, status, close, shop_id }) => {
  const [lan, setLan] = useState("english");
  useEffect(async () => {
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
  }, []);
  return (
    <Modal
      title=""
      placement="right"
      onCancel={close}
      visible={status}
      width={632}
      onOk={close}
    >
      <SubHeaderText>
        {lan === "bangla" ? "পেমেন্ট পদ্ধতি যুক্ত করুন" : "Add/Update payment method"}
      </SubHeaderText>

      <Tabs defaultActiveKey="1" centered>
        <TabPane
          tab={
            lan === "bangla"
              ? "মোবাইল ফিন্যান্সিয়াল সার্ভিস"
              : "Mobile Financial Service"
          }
          key="1"
        >
          <MFS refresh={refresh} close={close} shop_id={shop_id} />
        </TabPane>
        <TabPane tab={lan === "bangla" ? "ব্যাংক সার্ভিস" : "Bank Service"} key="2">
          <Banking refresh={refresh} close={close} shop_id={shop_id} />
        </TabPane>
      </Tabs>
      
    </Modal>
  );
};

export default AddPaymentMethod;
