
import { PhoneOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
const Container = styled.div`
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(172, 167, 167, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 20px;
  @media only screen and (max-width: 600px) {
    padding: 5px;
    img {
      width: 70px;
    }
    span {
      font-size: 15px;
    }
  }
`;
const HeadItem = styled.div`
  margin-top: 5px;
  padding: 5px;

  font-size: 18px;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
const Main = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  
  margin-bottom: 10px;
  width: 65%;
  margin: auto;
  p{
    padding: 5px 20px ;
    line-height: 27px;
  }
  @media only screen and (max-width: 600px){
    width: 85%;
  }
`;

const Agreement = () => {
  return (
    <>
      <Container>
        <HeadItem>
          <Row container spacing={2}>
            <Col item xs={4}>
              <a
                href="http://bahonexpress.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./images/logo.png" alt="" width="100px" />
              </a>
            </Col>
            <Col item xs={16} style={{ textAlign: "center", fontSize: "17px" }}>
              {" "}
              <span>MERCHANT SERVICE AGREEMENT</span>
            </Col>
            <Col item xs={4}>
              <PhoneOutlined style={{ color: "#3AB569", fontSize: "17px" }} />
              <span style={{ marginLeft: "10px", fontSize: "17px" }}>
                CALL US
              </span>
              <a
                href="tel:9613232469"
                style={{
                  textDecoration: "none",
                  marginLeft: "10px",
                  fontSize: "17px",
                  color: "#3AB569",
                }}
              >
                9613232469
              </a>
            </Col>
          </Row>
        </HeadItem>
       </Container>  
        <Main>
          

          <p>
             This Agreement is a legal agreement between the Merchant set forth on the registration with you and “BAHON EXPRESS”. This Agreement specifies the terms under which you 
            may access and use the Platform and order Additional Products and Services. Please read this
            Agreement carefully before accessing the Platform or ordering any Additional Products and Services,
              because by accessing the Platform, ordering any Additional Products and Services, clicking “I Agree,”
              or otherwise manifesting your assent to this Agreement, You agree to be bound by the terms of this
              Agreement. If you do not agree to (or cannot comply with) all of the terms of this Agreement, do
              not access or use the Platform or order any Additional Products or Service. The undersigned person 
              executing this Agreement on behalf of Merchant represents that he/ 
              she is an authorized representative of Merchant capable of binding it to this Agreement.
          </p>

          <p>
            All services of BAHON EXPRESS are offered and transacted under the Standard Trading Terms and Conditions
            as defined below. This Agreement shall be effective from the date hereof. By accepting all the
            terms and conditions for BAHON EXPRESS you are agreeing to all the following terms and conditions without exception. 
          </p>

          <p>
            PLEASE READ THE FOLLOWING MERCHANT SERVICES AGREEMENT TERMS AND CONDITIONS CAREFULLY 
            BEFORE ACCESSING OR USING BAHON EXPRESS SERVICES.
            THESE TERMS AND CONDITIONS CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND BAHON EXPRESS.
          </p>

          <p>
            <p>As a customer you agree to –</p>

            <ul>
              
              <li>Ensure all information supplied on the order is accurate and complete including but not limited to:-</li>
                <ul>
                  <li>Number of parcels being sent within a consignment</li>
                  <li>Sizes and weights of parcels</li>
                  <li>Addresses</li>
                  <li>Telephone numbers</li>
                  <li>Parcel Contents</li>
                  <li>Parcel Value</li>
                </ul>
                
              <li>Provide Bahon Express with such information that we may require to complete the service and to ensure that such information is accurate in all material aspects.</li>
              <li>BAHON EXPRESS delivery charges are calculated according to the higher of actual or volumetric weight per piece and any piece may be re-weighed and re-measured by BAHON EXPRESS to confirm this calculation.</li>  
              <li>If you want to cancel your delivery after placing the order, please contact us as soon as possible. You have the right to cancel your order but in that case you must notify us in advance.</li>
              <li>If is the customer’s responsibility to track their parcel using the tracking number provided while it is in transit. The customer must contact Bahon Express customer service department with any queries or issues they may have over a parcel/consignment.</li>
              <li>Each parcel or consignment sent through our service must have the correct information and label displayed on the box/ Package clearly for the courier to see. If this information is incorrect or placed on the wrong box Bahon Express cannot accept any liability for this.</li>
              <li>You agree to only send goods that are or were your property. My Parcel Delivery reserve the right to refuse to carry any parcel which are neither the property of, nor sent on behalf of, the customer.</li>
              <li>Any discrepancies in the information supplied may result in a delay in transit, loss, Charges or the parcel being returned to sender. If any additional costs are encountered by Bahon Express due to incorrect information supplied by the Customer, it will be the Customer’s responsibility to cover these costs.</li>
              <li>Cancellation of a booking must be received by 10pm the day before the collection date, any cancellations received after this time we reserve the right to charge a cancellation fee that will be deducted from the refund amount.</li>
              <li>Parcel protection is transit cover that provides cover against Loss and/or Damage. This cover does not apply to goods on the Restricted and/or Prohibited items list.</li>
              <li>Merchants are responsible for compliance with all legal requirements concerning the timeliness, completeness and accuracy of delivery information.</li>
              <li>The company reserves the right to change or modify any issue or policies relating to the service at any time if they want, if they think it is necessary.</li>
              <li>A parcel is deemed unacceptable if it contains animals, bullion, currency, weapons, explosives and ammunition, human remains, illegal items, such as ivory and narcotics etc.</li>
              <li>The Merchant acknowledge and agree that Driver may batch several orders for trip efficiencies (where applicable).</li>
              <li>BAHON EXPRESS will not be liable if your parcel delivery address is incorrect or not properly marked or its packaging is defective or inadequate to ensure safe transportation with ordinary care in handling.</li>
              <li>Posting any content that infringes the intellectual property rights, privacy rights, publicity rights, trade secret rights, or any other rights of any party will be considered as violence and against federal laws or regulations.</li>
              <li>Account funds, Payments, Cashback shall be separately recorded and the total balance of these credits shall be shown in your account through our apps at any time.</li>
              <li>Any content that constitutes cyber-bullying, posting content that depicts any dangerous, life-threatening, or otherwise risky behavior will be thoroughly reviewed and prosecuted.</li>
              <li>During the Term, BAHON EXPRESS shall publish to Customers via a pop-up located on our website and in the Branded Apps a link to BAHON EXPRESS’s privacy policy that describes the ways in which BAHON EXPRESS collects, uses, discloses, and protects Customer.</li>
              <li></li>
            </ul>
          </p>
          <br/>
          <p><strong>Acquirer Settlement Process</strong></p>
          <p>The Acquirer will process Settlement payments to the Merchant's nominated settlement bank account/ Bkash number or COD basis, 
            as specified in the Merchant Application Form. The Payment Methods are open to choose by merchant.
          </p>
          <p>
            <strong>Refunds: </strong> In case of any misconduct on the part of BAHON EXPRESS, then BAHON EXPRESS will do their best to review it thoroughly and contact the merchant immediately. A full or partial reversal of
               a particular transaction, whereby the funds are reimbursed to the buyer on the initiative or request of the Merchant.
             
          </p>
          <p><strong>Returns: </strong>
              Bahon Express will provide the facility, if a customer returns any product then the product will be delivered to the merchants within 24 hours.
          </p>
          <p><strong>Damage: </strong>
              When the riders pick up the parcel from you, riders are bound to check and take video/picture of the products in front of you.  The riders has the option to reject/ confirm from authority before the delivery, if they see any damage of the product. For most damage, the riders is responsible, unless the riders claims that the packaging was inadequate. When parcel is damaged during delivery time due to rider’s 
              carrying fault, NOT THE PRODUCT DAMEGE, on behalf of the riders Bahon Express will minimize the risk of damages as per discussion.
          </p>
          <br/>
          <p><strong>Merchant Obligations</strong></p>
          <p>(a) The Merchant agrees to provide the Acquirer with a copy of the Merchant's valid and effective business license and certificate of incorporation (if applicable) and relevant background information upon the Acquirer's reasonable request.</p>
          <p>(b) The Merchant warrants, represents and certifies that all information contained in this Agreement supplied by the Merchant and any other documentation submitted in support of this Agreement (including, among others, a copy of the Merchant business license and certificate of incorporation (if applicable)), are complete, true and correct.</p>
          <p>(c) The Merchant shall be responsible to ensure it will properly use BAHON EXPRESS’s transaction management system provided by BAHON EXPRESS and shall ensure its system meets the software requirements and service processes provided by BAHON EXPRESS with respect to order handling and goods delivery or service provision, which may be updated from time to time. In particular, the Merchant shall ensure it facilitates the provision of the BAHON EXPRESS Services at its own costs through keeping the relevant software and hardware in a good operational condition and appropriately links to BAHON EXPRESS software system.</p>
          <p>(d) The Merchant shall ensure that BAHON EXPRESS’s logo and content must be used only for the purpose of indicating acceptance of BAHON EXPRESS by the Merchant as a form of payment, unless otherwise authorized by BAHON EXPRESS. The Merchant shall present a truthful description of BAHON EXPRESS services in accordance with BAHON EXPRESS’s guidance and introduction, and direct BAHON EXPRESS Users to access BAHON EXPRESS's Platform.</p>
          <br/>
          <p><strong>Confidential Information</strong></p>
          <p>"Confidential Information" means any confidential, trade secret or proprietary information (which may be business, financial or technical information) disclosed by one party to the other under this Agreement that is marked confidential or if disclosed orally designated as confidential at the time of disclosure or that should be reasonably understood to be confidential. </p>
          <br/>
          <p><strong>Term and Termination</strong></p>

          <ul style={{listStyleType: 'upper-roman'}}>
            <li>This Agreement will continue to renew automatically for successive one-year terms until terminated with 30-day notice prior to the end of the then current term if either Merchant or Acquirer decides not to renew.</li>
            <li>Any party who intends to terminate the Agreement shall give advance written notice to the other party not less than ninety (90) days prior to the termination of this Agreement.</li>
          </ul>
        </Main>

     
    </>
  );
};

export default Agreement;
