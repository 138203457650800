import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCoverageAreas, updateStore,fetchStore,uniqueShopNameUpdate } from "../../action/shop";
const { Option } = Select;
const EditStore = ({
  status,
  close,
  refresh,
  getCoverageAreas,
  updateStore,
  fetchStore,
  shop_id,
  uniqueShopNameUpdate
}) => {
  const [coverageArea, setCoverageArea] = useState(null);
  const [lan, setLan] = useState("english");
  const [city, setCity] = useState(null);
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);
  const [isUnique, setIsUnique] = useState(true);

  const [form] = Form.useForm();
  useEffect(async () => {
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
    const res = await getCoverageAreas();
    setCoverageArea(res);

    // ========== Fetching Shop Info ==========

    const result = await fetchStore(shop_id)
    let c = result?.pickup_area_code.split('-')[0]
    let sc = result?.pickup_area_code.split('-')[1]
    let t = result?.pickup_area_code.split('-')[2]
    let a = result?.pickup_area_code.split('-')[3]
    
    setArea(a)
    
    setCity(Number(c))
    const subCity = [...res.city[c - 1]?.sub_city];
    setsubCityArr(subCity);

    setSubCity(sc)
    const thana = [...subCity[sc - 1].thana];
    setThanaArr(thana);
    setAreaArr(null);
    
    setThana(t)
    const area = [...thana[t - 1].area];
    setAreaArr(area);


    form.setFieldsValue({
      name: result?.name,
      email: result?.email,
      mobile_number: result?.mobile_number,
      trade_license: result?.trade_license,
      city: Number(result?.pickup_area_code.split('-')[0]),
      subCity: Number(result?.pickup_area_code.split('-')[1]),
      thana: Number(result?.pickup_area_code.split('-')[2]),
      area: Number(result?.pickup_area_code.split('-')[3]),
      pickup_address: result?.pickup_address,
      address: result?.address,
      pickup_type: result?.pickup_type,
      active: result?.active,
      shop_id: shop_id
    })
  }, []);

  const handleNameCheck = async (e) => {
    const res = await uniqueShopNameUpdate(e.target.value, shop_id)
    setIsUnique(res)
  }

  const pickup_type = [
    { label: "Select One", value: "" },
    { label: "Electronics", value: "Electronics" },
    { label: "Health & Beauty", value: "Health & Beauty" },
    { label: "Jewelry", value: "Jewelry" },
    { label: "Clothing", value: "Clothing" },
    { label: "Craft", value: "Craft" },
    { label: "Fashion Accessories", value: "Fashion Accessories" },
    { label: "Fragile", value: "Fragile" },
    { label: "Groceries", value: "Groceries" },
    { label: "Others", value: "Others" },
  ];
  const active = [
    { label: "Select One", value: "" },
    { label: "Active", value: true },
    { label: "In active", value: false },
  ];

  const city_ = async (event) => {
    const cityCode = event;
    // if (cityCode === "-1") {
    //   return;
    // }
    
    const subCity = [...coverageArea.city[cityCode - 1]?.sub_city];
    
    // setCity({ cityCode });
    setsubCityArr(subCity);
    // setSubCity("");
    // setThana("");
    // setArea("");
    setThanaArr(null);
    setAreaArr(null);
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    // setSubCity(subCode);
    // setThana("");
    // setArea("");
    setThanaArr(thana);
    setAreaArr(null);
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];
    // setThana(thanaCode);
    setAreaArr(area);
    // setArea("");
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    // setArea(areaCode);
  };

  // ========== Cities =========
  const c_o =
    coverageArea != null
      ? coverageArea.city.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              
              {item.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;
  const onFinish = async (values) => {
    const res = await updateStore(values);
    if (res === 201) {
      refresh();
      close();
    }
  };

  const onFinishFailed = (errorInfo) => {};
  const fullWidth = global.window.innerWidth
  return (
    <Drawer
      width={fullWidth}
      onClose={close}
      visible={status}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={close}>Cancel</Button>
        </Space>
      }
    >
      <h2 style={{ padding: "20px", fontWeight: "600", textAlign: "center" }}>
        {lan === "bangla" ? "স্টোর আপডেট করুন" : "Update your store"}
      </h2>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={ isUnique ? 
                lan === "bangla" ? "স্টোরের নাম" : "Store Name" 
                : lan === "bangla" ? <span style={{color: 'red'}}>স্টোরের নামটি পূর্বে ব্যবহার করা হয়ে গিয়েছে </span> : <span style={{color: 'red'}}>Store name is exist</span> }
              rules={[{ required: true, message: "Please enter user name" }]}
              onChange={(e)=>handleNameCheck(e)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Form.Item
              style={{display: 'none'}}
              name="shop_id"
              label={lan === "bangla" ? "স্টোরের নাম" : "Store Name"}
              rules={[{ required: true, message: "Please enter user name" }]}
            >
              <Input name="shop_id" type="hidden" />
            </Form.Item>
          <Col span={12}>
            <Form.Item
              name="email"
              
              label={lan === "bangla" ? "ইমেইল এড্রেস" : "Email Address"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "ইমেইল এড্রেস অত্যাবশ্যকীয়"
                      : "Email Address is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="mobile_number"
              label={
                lan === "bangla"
                  ? "স্টোরের মোবাইল নাম্বার"
                  : "Store Mobile Number"
              }
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "স্টোরের মোবাইল নাম্বার অত্যাবশ্যকীয়"
                      : "Store Mobile is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="trade_license"
              label={
                lan === "bangla"
                  ? " স্টোরের ট্রেড লাইসেন্স (ঐচ্ছিক)"
                  : "Trade License (Optional)"
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
         {area ?
         <>
         <Row gutter={16}>
          {/* ================ City =============== */}
         
          <Col span={12}>
            <Form.Item
              name="city"
              
              label={lan === "bangla" ? " স্টোরের সিটি" : "Select City"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? " স্টোরের সিটি অত্যাবশ্যকীয়"
                      : "City  is required",
                },
              ]}
            >
              <Select
                placeholder={
                  lan === "bangla"
                    ? " স্টোরের সিটি সিলেক্ট করুন"
                    : "Select one city"
                }
                onChange={(e) => city_(e)}
              >
                {/* <Option value="-1">Jack Ma</Option> */}
                {c_o}
              </Select>
            </Form.Item>
          </Col>
          
          {/* ================ Sub City ============ */}
          {subCityArr ? (
            <Col span={12}>
              <Form.Item
                name="subCity"
                label={
                  lan === "bangla" ? " স্টোরের সাব-সিটি" : "Select Subcity"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? " স্টোরের সাব-সিটি অত্যাবশ্যকীয়"
                        : "Subcity is required",
                  },
                ]}
              >
                <Select
                // defaultValue={Number(subCity)}
                  placeholder={
                    lan === "bangla"
                      ? " স্টোরের সাব-সিটি সিলেক্ট করুন"
                      : "Select one subcity"
                  }
                  onChange={(e) => sub_city(e)}
                >
                  {/* <Option value="-1">Select one</Option> */}
                  {s_o}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        <Row gutter={16}>
          {/* ============== Thana ============== */}
          {thanaArr ? (
            <Col span={12}>
              <Form.Item
                name="thana"
                label={
                  lan === "bangla"
                    ? " স্টোরের থানা সিলেক্ট করুন"
                    : "Select one thana"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? " স্টোরের থানা অত্যাবশ্যকীয়"
                        : "Thana is required",
                  },
                ]}
              >
                <Select
                  // defaultValue={thana}
                  placeholder={
                    lan === "bangla"
                      ? " স্টোরের থানা সিলেক্ট করুন"
                      : "Select one subcity"
                  }
                  onChange={(e) => thana_(e)}
                >
                  {/* <Option value="-1">Select Thana</Option> */}
                  {t_o}
                </Select>
              </Form.Item>
            </Col>
          ) : null}

          {/* ============== Area ============= */}
          {areaArr ? (
            <Col span={12}>
              <Form.Item
                name="area"
                label={
                  lan === "bangla"
                    ? " স্টোরের এরিয়া সিলেক্ট করুন"
                    : "Select one area"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? " স্টোরের এরিয়া অত্যাবশ্যকীয়"
                        : "Area is required",
                  },
                ]}
              >
                <Select
                  // defaultValue={area}
                  placeholder={
                    lan === "bangla"
                      ? " স্টোরের এরিয়া সিলেক্ট করুন"
                      : "Select one area"
                  }
                  onChange={(e) => area_(e)}
                >
                  {/* <Option value="-1">Select Area</Option> */}
                  {a_o}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        </>
        : null}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="pickup_address"
              label={
                lan === "bangla" ? " পার্সেলের পিক-আপ এড্রেস" : "Pickup Address"
              }
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "পিক-আপ এড্রেস অত্যাবশ্যকীয়"
                      : "Pickup Address is required",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label={lan === "bangla" ? "স্টোরের এড্রেস" : "Store Address"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "স্টোরের এড্রেস অত্যাবশ্যকীয়"
                      : "Address is required",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="pickup_type"
              label={lan === "bangla" ? " পিক আপ এর ধরন" : "Pickup Type"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? " পিক আপ এর ধরন অত্যাবশ্যকীয়"
                      : "Pickup type is required",
                },
              ]}
            >
              <Select
                placeholder={
                  lan === "bangla" ? " পিক আপ এর ধরন" : "Pickup Type"
                }
              >
                {pickup_type?.map((item, id) => (
                  <Option key={id} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="active"
              label={lan === "bangla" ? "স্টোর এর স্টেটাস" : "Status"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "অত্যাবশ্যকীয়"
                      : "Pickup type is required",
                },
              ]}
            >
              <Select
                placeholder={
                  lan === "bangla" ? " স্টোর এর স্টেটাস" : "Status"
                }
              >
                {active?.map((item, id) => (
                  <Option key={id} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>&nbsp;</Col>
          <Col span={24}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button htmlType="submit" type="primary" disabled={!isUnique ? 'disabled' : null }>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default connect(null, { getCoverageAreas, updateStore, fetchStore,uniqueShopNameUpdate })(EditStore);
