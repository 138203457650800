import { Button, Col, Empty, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../history";
import {Container, Card, FlexContainer } from '../../styles/globalStyle';
import { StatcCard } from "./style";
import {fetchShops,getStoreStatictics} from '../../action/shop';
import CreateStore from "../store/createStore";
import {notificationList,notificationCount} from "../../action/support";
const { Option } = Select;
const Dashboard = ({fetchShops,getStoreStatictics,notificationList,notificationCount}) => {
  const [shops, setShops] = useState(null)
  const [shopStac, setShopStac] = useState(null)
  const [lan, setLan] = useState("")
  const [createForm, setCreateForm] = useState(false)
  const [storeName, setStoreName] = useState(null)
  const [notification, setNotification] = useState([])
  useEffect( async () => {
    
    setLan(localStorage.getItem("lan")
    ? localStorage.getItem("lan")
    : "english")
    if (localStorage.getItem("token")) {
      notificationCount()
    } else {
      localStorage.clear();
      history.push("/login");
    }
    const noti = await notificationList()
    setNotification(noti)
    let active_store_name =  localStorage.getItem('active_store_name') ? localStorage.getItem('active_store_name') : null;
    if(active_store_name){
      const res =  await fetchShops()
      setShops(res)
      const stac = await getStoreStatictics(localStorage.getItem('active_store'))
      setShopStac(stac)
      setStoreName(active_store_name)
      
    }else{
      const res =  await fetchShops()
      setShops(res)
      if(res?.length > 0){
        const stac = await getStoreStatictics(res[0]?.shop_id)
        setShopStac(stac)
        setStoreName(res[0]?.name)
      }
    }

  }, []);

  const  createOptions = (label, value) => {
    return { label, value };
  };

  const getStatistics = async () => {
    const res =  await fetchShops()
    setShops(res)
    if(res?.length > 0){
      const stac = await getStoreStatictics(res[0]?.shop_id)
      setShopStac(stac)
      setStoreName(res[0]?.name)
    }
  }
  const onChange = async (e) => {

    const res = await getStoreStatictics(e.split('-')[0])
    setShopStac(res)
    setStoreName(e.split('-')[1])
    localStorage.setItem('active_store',e.split('-')[0])
    localStorage.setItem('active_store_name',e.split('-')[1])
  }
  
  const onClose = () => {
    setCreateForm(false)
  }
  let option = [];
    option = shops
      ? shops.map((item) => createOptions(item.name, item.shop_id))
      : null;
  
    const onCreate = () => {
        setCreateForm(true)
    }
    const noStore = () => (
          shops?.length === 0 ?
          <FlexContainer style={{ height: "50vh" }}>
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  fontWeight: "600",
                }}
              >
                {lan === "bangla"
                  ? "নতুন স্টোর তৈরি করুন"
                  : "Create Your new store"}
              </h1>
              <p style={{ textAlign: "center", color: "gray" }}>
                {lan === "bangla"
                  ? "আপনার কোনো স্টোর এখনো নেই, পার্সেল এড কয়ার জন্য স্টোর তৈরি করুন"
                  : "You don't have store. Add store to create parcel"}
              </p>
              <Button
                type="primary"
                style={{ textAlign: "center" }}
                onClick={onCreate}
              >
                {lan === "bangla" ? "এড স্টোর" : "Add Store"}
              </Button>
            </div>
          </FlexContainer>
          :
          null
    )
    const dataSection = () => (
      shops?.length > 0 ?
      <Card style={{backgroundColor: '#FFF',height: '520px'}}>
        <Row gutter={16}>
          <Col xs={24}>
            <Select
            showSearch
            placeholder="Select a shop"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {option?.map((item,id ) => (
                <Option value={item.value+"-"+item.label}>{item.label}</Option>
            ))}
          </Select>
          </Col>
          {shopStac ? 
            <>
            
            <Col xs={24}>
              <p style={{textAlign: 'center'}}>Current Store - {storeName}</p>
            </Col>
            <Col xs={12}>
              <StatcCard>
                <h1>{shopStac?.pending_delivery}</h1>
                <p>Pending Delivery</p>
              </StatcCard>
            </Col>
            
            <Col xs={12}>
              <StatcCard>
                <h1>{shopStac?.pending_return}</h1>
                <p>Pending Return</p>
              </StatcCard>
            </Col>
            
            <Col xs={12}>
              <StatcCard>
                <h1>{shopStac?.total_delivered}</h1>
                <p>Total Delivered</p>
              </StatcCard>
            </Col>
            
            <Col xs={12}>
              <StatcCard>
                <h1>{shopStac?.total_returned}</h1>
                <p>Total Returned</p>
              </StatcCard>
            </Col>
            
            
            <Col xs={12}>
              <StatcCard>
                <h1>{shopStac?.total_order}</h1>
                <p>Total Order</p>
              </StatcCard>
            </Col>
            
            <Col xs={12}>
              <StatcCard>
                <h1>{shopStac?.account_balance} &#2547;</h1>
                <p>Account Balance</p>
              </StatcCard>
            </Col>
            
          
            </>
          : null}

        </Row>
      </Card>
     :null
    )

    const noticeSection = () => (
      <Card style={{backgroundColor: '#FFF', paddingTop: '0px', height: '520px', overflowY: 'scroll', justifyContent: 'flex-start'}}>
        <h1 style={{position: 'sticky',top: '0',padding: '10px', backgroundColor: '#FFF'}}>Notices</h1>
        {notification?.length > 0 ?
        <ul>
          {notification?.map((item,id) => (
            <>

            <li key={id}
            style={id % 2 !== 0 ? {borderLeftColor: '#74b9ff'} : {borderLeftColor: '#6c5ce7'}}
            
            >
              
              <h4 style={{fontWeight: 'bold'}} >{item?.title}</h4>
              {item?.message}</li>
            </>
          ))}
         </ul>
       :
       <Empty />}
        
      </Card>
    )

    const getShops = async () => {
      const res = await fetchShops()
      if(res){
          setShops(res)
      }
    }
    
    const handleRefresh = async () => {
      getShops()
    }

  return (
  <Container>
    <Row gutter={16}>
      <Col xs={24} sm={16}> 
      {noStore()}
      {dataSection()}
      
      </Col>
      <Col xs={24} sm={8}>
        {noticeSection()}
      </Col>
    </Row>
    
    <CreateStore status={createForm} close={onClose} refresh = {handleRefresh} re={true} shopFnc = {getStatistics} />
  </Container>
  );
};

export default connect(null, {fetchShops,getStoreStatictics,notificationList,notificationCount})(Dashboard);
