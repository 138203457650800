
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route,Switch, Redirect } from 'react-router-dom';
import './App.css';
import MaintenancePage from './components/maintenance';
import NotFound from './components/notFound';
import history from './history';
import MainLayout from './layout';
import Agreement from './pages/registration/agreement';
import GlobalStyles from './styles/globalStyle';
import {refresh} from './action/other'
function App({refresh}) {
  const [flag, setflag] = useState(false)
  useEffect( async () => {
    if(localStorage.getItem('token')){
      await refresh()
    }
    
    setflag(true)
  }, [])
  
  return (
    // ============ Main Router =========
    flag ? 
      <Router history={history} >

     {/* ======== Global Style with font family ========= */}

      <GlobalStyles />

      {/* ============== Main Route Switcher ======== */}
      <Switch>

          <Route path="/maintenance" component={MaintenancePage}/>
          <Route path="/404" component={NotFound}/>
          <Route path="/terms" component={Agreement}/>

          {/* ========== All pages========= */}
          <Route path="/" component={MainLayout}/>

          {/* === If no route found === */}
          <Redirect to="/404" />

      </Switch>
    </Router>
      :
    <>
    </>
  
    
  );
}

export default connect(null,{refresh})(App);
