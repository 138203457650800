import styled from "styled-components";

export const StatcCard = styled.div`
background-color: #fff;
display: flex;
justify-content: center;
flex-direction: column;
padding: 15px 20px;
border-radius: 10px;
box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
-webkit-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
-moz-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
margin: 15px 0px;
min-height: auto;
    h1{
        font-weight: 600 ;
        padding: 0px 10px ;
    }
    ul{
        padding: 10px ;
    }
    li{
        list-style-type: none;
        margin: 15px 0px ;
        border-left: 5px solid green ;
        padding: 0px 5px ;
    }
`;