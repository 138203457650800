import api from "./apis";
import history from "../history";
import { ERROR_MESSAGE, LOADED, LOADING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./type";

export const getPaymentOtp = (data) => async (dispatch) => {
    try {
      // dispatch({ type: LOADING });
      const response = await api(localStorage.getItem("token")).put("/add-payment", data);
      if (response.status === 201 && response.data !== "") {
        // dispatch({ type: LOADED });
        const code = response.status;
        dispatch({ type: LOADED });
        return code;
      }
    } catch (err) {
        // dispatch({ type: LOADED });
        
        if(err?.response?.status === 422){
          err.response.data.errors ?
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }

        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : history.push('/opps'),
        });
    }
  };

export const confirmPaymentOTP = (shop_id, OTP) => async (dispatch) => {
  
    dispatch({ type: LOADING });
    let obj = {
            "shop_id": shop_id,
            "OTP": OTP,
    }
      try {
        const response = await api(localStorage.getItem("token")).patch("/verify-payment", {...obj});
      
          if (response.status === 201) { 
                dispatch( {type: LOADED });
                dispatch({ type: SUCCESS_MESSAGE ,  payload: response.data.message});
                return response.status
            }
      } catch (err) {
        dispatch( {type: LOADED });
         if(err.response)
          {
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/error') });
      
      }
    };
  
export const resendPaymentOtp = (shop_id) => async (dispatch) => {
   
      // dispatch({ type: LOADING });
      const obj = {
        'shop_id': shop_id,
        'type': 'APM',
      }
      try {
        const response = await api(localStorage.getItem("token")).patch("/new-otp", {...obj});
    
        if (response.status === 201) {
          
          // dispatch({ type: LOADED });
          dispatch({ type: SUCCESS_MESSAGE, payload: 'OTP is send to your number' });
          
        } 
      } catch (err) {
         if(err.response)
            {
              // dispatch( {type: LOADED });
                if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
            }
        // dispatch({ type: LOADED });
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : history.push('/opps'),
        });
      }
    };

export const getTransaction = (shop_id) => async (dispatch) => {
    dispatch({ type: LOADING });
  
      try {
        const response = await api(localStorage.getItem("token")).get(`/shop-transaction?shop_id=${shop_id}`);
      
        if (response.status === 200) {
          // dispatch({ type : FETCH_TRANSACTION, payload : response.data })
          dispatch({ type: LOADED });
          return response.data;
        }
      } catch (err) {
          if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/error') });
        dispatch({ type: LOADED });
      }
    };
