import { Button, Result } from 'antd';
import React from 'react'
import { FlexContainer } from '../styles/globalStyle';
import history from '../history';
const MaintenancePage = () => {
    return ( 
        <FlexContainer>
            <Result
                icon={<img src='/images/maintanence.gif' style={{width: '250px'}} />}
                title="Opps!"
                subTitle=" Something went wrong. Please check your internet connection or try again later"
                extra={<Button onClick={()=> history.push('/') } type="primary">Back Home</Button>}
                style={{alignSelf: 'center'}}
            />
        </FlexContainer>
     );
}
 
export default MaintenancePage;