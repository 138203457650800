import api from "./apis";
import { COUNTER, ERROR_MESSAGE, LOADED, LOADING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./type";
import history from "../history";
import axios from "axios";


export const getSupport = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/ticket`);
    
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.tickets;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
        if(err?.response?.status === 451 ){
          history.push('/pending-verification')
        }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }
        
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const createTicket = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
    let file = null
    if(data.file !== undefined)
      if(data.file.fileList.length > 0){
        Object.keys(data).map((ele) => {
        
            if(typeof(data[ele]) === 'object'){
    
                Object.keys(data[ele]).map((last,id) => {
                  if(last === 'fileList'){
                    file=data[ele][last][0].response.name
                  
                    }
                })
              }
            
        });
      
  }
  try{
    let obj = null
    if(data?.file?.fileList?.length > 0){
      obj = {
        title : data.title,
        message : data.message,
        file : file,
      }
    }
    else{
      obj = {
        title : data.title,
        message : data.message,
      }
    }
  
    const response = await api(localStorage.getItem("token")).post('ticket/create', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Ticket is successfully created!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
      if(err?.response?.status === 451)
      {
        history.push('/pending-verification')
      }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const getSupportDetails = (ticket) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/ticket/detail?ticket=${ticket}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.ticket;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }
        
      if(err?.response?.status === 451)
      {
        history.push('/pending-verification')
      }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const supportReply = (data,ticket) => async (dispatch) => {
  dispatch({ type: LOADING });
    let file = null
    if(data.file !== undefined)
      if(data.file.fileList.length > 0){
        Object.keys(data).map((ele) => {
        
            if(typeof(data[ele]) === 'object'){
    
                Object.keys(data[ele]).map((last,id) => {
                  if(last === 'fileList'){
                    file=data[ele][last][0].response.name
                  
                    }
                })
              }
            
        });
      
  }
  try{
    let obj = null
    if(data?.file?.fileList?.length > 0){
      obj = {
        ticket : ticket,
        message : data.text,
        file : file,
      }
    }
    else{
      obj = {
        ticket : ticket,
        message : data.text,
      }
    }
    const response = await api(localStorage.getItem("token")).patch('ticket/reply', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Reply is successfully send!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        
      if(err?.response?.status === 451)
      {
        history.push('/pending-verification')
      }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};


export const supportFileDownloader = (name) => async (dispatch) => {
  dispatch({ type: LOADING });
  const ext = name.split(".")[1]
  
  try{
    const response = await axios(`https://api.bahonexpress.com/merchant/file/download?file_name=${name}&file=ticket`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.${ext}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const closeTicket = (ticket,remark) => async (dispatch) => {
  dispatch({ type: LOADING });
   console.log(remark)
  try{
  
    const obj = {
        ticket : ticket,
        remark : remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch('ticket/closed', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Ticket is successfully closed!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        
      if(err?.response?.status === 451)
      {
        history.push('/pending-verification')
      }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const notificationCount = () => async (dispatch) => {

    try{
        const response = await api(localStorage.getItem("token")).get('/ticket/count');
        if(response.status === 200 ){
                    dispatch({type: LOADED})
                    dispatch({ type: COUNTER, payload: response.data.count})
                
        }
    }
    catch(err){
        
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          
          if(err?.response?.status === 451 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            history.push('/pending-verification')
          }

          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }

          if(err.message == 'Network Error')
          {
              history.push('/maintenance')
              return
          }
          dispatch({ type: ERROR_MESSAGE, payload: err?.response ? err.response.data.message : history.push('/404') });
          dispatch({type: LOADED});
    }
}
export const mainnotificationCount = () => async (dispatch) => {

    try{
        const response = await api(localStorage.getItem("token")).get('/notification/count');
        if(response.status === 200 ){
                    dispatch({type: LOADED})
                    return response.data.count
                
        }
    }
    catch(err){
        
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          
          if(err?.response?.status === 451 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            history.push('/pending-verification')
          }

          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }

          if(err.message == 'Network Error')
          {
              history.push('/maintenance')
              return
          }
          dispatch({ type: ERROR_MESSAGE, payload: err?.response ? err.response.data.message : history.push('/404') });
          dispatch({type: LOADED});
    }
}
export const notificationList = () => async (dispatch) => {
    // dispatch({type: LOADING})
    try{
        const response = await api(localStorage.getItem("token")).get('/notice');
        if(response.status === 200 ){
            dispatch({type: LOADED})
            return response?.data?.notice
        }
    }
    catch(err){
        
        if(err?.response?.status === 451 ){
            history.push('/pending-verification')
          }
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
          dispatch({type: LOADED});
    }
}
export const mainnotificationList = () => async (dispatch) => {
    // dispatch({type: LOADING})
    try{
        const response = await api(localStorage.getItem("token")).get('/notification');
        if(response.status === 200 ){
            dispatch({type: LOADED})
            return response?.data?.notification
        }
    }
    catch(err){
        
        if(err?.response?.status === 451 ){
            history.push('/pending-verification')
          }
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
          dispatch({type: LOADED});
    }
}