import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Input, Select } from "antd";
import {
  getPaymentOtp,
  confirmPaymentOTP,
  resendPaymentOtp,
} from "../../action/paymentAction";

import { connect } from "react-redux";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import Countdown from "antd/lib/statistic/Countdown";
const { Option } = Select;
const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: auto;
  max-height: auto;
  img {
    width: 150px;
    align-self: center;
    margin-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-weight: 600;
  }
  p {
    text-align: center;
    font-weight: 600;
    color: gray;
  }
  button {
    text-align: center;
  }
`;
const Banking = ({
  getPaymentOtp,
  shop_id,
  refresh,
  close,
  confirmPaymentOTP,
  resendPaymentOtp,
}) => {
  const [lan, setLan] = useState("english");
  const [val, setVal] = useState("");
  const [resend, setResend] = useState("");
  const [submit, setSubmit] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(async () => {
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
  }, []);
  const banks = [
    { label: "Select One", value: "" },
    { label: "AB Bank LTD", value: "AB Bank LTD" },
    { label: "Agrani Bank LTD", value: "Agrani Bank LTD" },
    { label: "Al-Arafah Islami Bank LTD", value: "Al-Arafah Islami Bank LTD" },
    { label: "BASIC Bank LTD", value: "BASIC Bank LTD" },
    {
      label: "BDBL (Bangladesh Development Bank LTD)",
      value: "BDBL (Bangladesh Development Bank LTD)",
    },
    { label: "BRAC Bank LTD", value: "BRAC Bank LTD" },
    {
      label: "Bangladesh Commerce Bank LTD",
      value: "Bangladesh Commerce Bank LTD",
    },
    { label: "Bank Al-Falah", value: "Bank Al-Falah" },
    { label: "Bank Asia LTD", value: "Bank Asia LTD" },
    { label: "Citibank NA", value: "Citibank NA" },
    { label: "Commercial Bank of Ceylon", value: "Commercial Bank of Ceylon" },
    { label: "Dhaka Bank LTD", value: "Dhaka Bank LTD" },
    { label: "Dutch Bangla Bank LTD", value: "Dutch Bangla Bank LTD" },
    { label: "Eastern Bank LTD", value: "Eastern Bank LTD" },
    {
      label: "Export Import Bank of Bangladesh LTD",
      value: "Export Import Bank of Bangladesh LTD",
    },
    {
      label: "First Security Islami Bank LTD",
      value: "First Security Islami Bank LTD",
    },
    { label: "HSBC", value: "HSBC" },
    { label: "Habib Bank LTD", value: "Habib Bank LTD" },
    { label: "ICB Islamic Bank LTD", value: "ICB Islamic Bank LTD" },
    { label: "IFIC Bank LTD", value: "IFIC Bank LTD" },
    {
      label: "Islami Bank Bangladesh LTD",
      value: "Islami Bank Bangladesh LTD",
    },
    { label: "Jamuna Bank LTD", value: "Jamuna Bank LTD" },
    { label: "Janata Bank LTD", value: "Janata Bank LTD" },
    { label: "Meghna Bank LTD", value: "Meghna Bank LTD" },
    { label: "Mercantile Bank LTD", value: "Mercantile Bank LTD" },
    { label: "Midland Bank LTD", value: "Midland Bank LTD" },
    { label: "Modhumoti Bank LTD", value: "Modhumoti Bank LTD" },
    { label: "Mutual Trust Bank LTD", value: "Mutual Trust Bank LTD" },
    { label: "NCC Bank LTD", value: "NCC Bank LTD" },
    { label: "NRB Bank LTD", value: "NRB Bank LTD" },
    { label: "NRB Commercial Bank LTD", value: "NRB Commercial Bank LTD" },
    { label: "NRB Global Bank LTD", value: "NRB Global Bank LTD" },
    { label: "National Bank LTD", value: "National Bank LTD" },
    { label: "National Bank of Pakistan", value: "National Bank of Pakistan" },
    { label: "One Bank LTD", value: "One Bank LTD" },
    { label: "Prime Bank LTD", value: "Prime Bank LTD" },
    { label: "Pubali Bank LTD", value: "Pubali Bank LTD" },
    { label: "Rupali Bank LTD", value: "Rupali Bank LTD" },
    { label: "Shahjalal islami Bank LTD", value: "Shahjalal islami Bank LTD" },
    { label: "Simanto Bank LTD", value: "Simanto Bank LTD" },
    { label: "Social Islami Bank LTD", value: "Social Islami Bank LTD" },
    { label: "Sonali Bank LTD", value: "Sonali Bank LTD" },
    {
      label: "South Bangla Agriculture and Commerce Bank LTD",
      value: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { label: "Southeast Bank LTD", value: "Southeast Bank LTD" },
    { label: "Standard Bank LTD", value: "Standard Bank LTD" },
    { label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
    { label: "State Bank of India", value: "State Bank of India" },
    { label: "The City Bank LTD", value: "The City Bank LTD" },
    { label: "The Farmers Bank LTD", value: "The Farmers Bank LTD" },
    { label: "The Premier Bank LTD", value: "The Premier Bank LTD" },
    { label: "Trust Bank LTD", value: "Trust Bank LTD" },
    { label: "Union Bank LTD", value: "Union Bank LTD" },
    {
      label: "United Commercial Bank LTD",
      value: "United Commercial Bank LTD",
    },
    { label: "Uttara Bank LTD", value: "Uttara Bank LTD" },
    { label: "Woori Bank", value: "Woori Bank" },
  ];
  const account_type = [
    { label: "Select One", value: "" },
    { label: "Saving", value: "Saving" },
    { label: "Current", value: "Current" },
  ];
  const onFinish = async (values) => {
    const preData = { ...values };
    preData["shop_id"] = shop_id;
    preData["payment_method"] = "Bank Account";
    const code = await getPaymentOtp(preData);
    if (code == 201) {
      setShow(true);
    }
  };
  const handleResendOTP = async () => {
    const res = await resendPaymentOtp(shop_id);
    setVal("");
    setResend(false);
  };

  const submitOtp = async () => {
    //   console.log(close())

    const res = await confirmPaymentOTP(shop_id, val);
    if (res === 201) {
      setVal("");
      refresh();
      close();
    } else {
      setVal("");
      setSubmit(false);
    }
  };

  const setOTP = async (e) => {
    setVal(e);
    if (e.length > 3) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };
  const deadline = Date.now() + 1000 * 90;
  return !show ? (
    <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="bank_name"
            label={lan === "bangla" ? "ব্যাংক নাম" : "Bank Name"}
            rules={[
              {
                required: true,
                message:
                  lan === "bangla" ? "অত্যাবশ্যকীয়" : "This field is required",
              },
            ]}
            
          >
            <Select
              placeholder={
                lan === "bangla"
                  ? "একটি অপশন সিলেক্ট করুন"
                  : "Please select an option"
              }
              showSearch
              
            >
              {banks?.map((item, id) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="branch_name"
            label={lan === "bangla" ? "ব্রাঞ্চ নাম" : "Branch Name"}
            rules={[
              {
                required: true,
                message:
                  lan === "bangla" ? "অত্যাবশ্যকীয়" : "This field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="account_type"
            label={lan === "bangla" ? "একাউন্ট টাইপ" : "Account Type"}
            rules={[
              {
                required: true,
                message:
                  lan === "bangla" ? "অত্যাবশ্যকীয়" : "This field is required",
              },
            ]}
          >
            <Select
              placeholder={
                lan === "bangla"
                  ? "একটি অপশন সিলেক্ট করুন"
                  : "Please select an option"
              }
            >
              {account_type?.map((item, id) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="account_name"
            label={lan === "bangla" ? "একাউন্ট এর নাম" : "Account Name"}
            rules={[
              {
                required: true,
                message:
                  lan === "bangla" ? "অত্যাবশ্যকীয়" : "This field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="account_number"
            label={lan === "bangla" ? "একাউন্ট এর নাম্বার" : "Account Number"}
            rules={[
              {
                required: true,
                message:
                  lan === "bangla" ? "অত্যাবশ্যকীয়" : "This field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="routing_number"
            label={lan === "bangla" ? "রাউটিং নাম্বার" : "Routing Number"}
            rules={[
              {
                required: true,
                message:
                  lan === "bangla" ? "অত্যাবশ্যকীয়" : "This field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            {lan === "bangla" ? "জমা দিন" : "Submit"}
          </Button>
        </Col>
      </Row>
    </Form>
  ) : (
    <ModalContainer>
      <h2>{lan == "bangla" ? "ওটিপি ভেরিফাই করুন" : "Verify your account"}</h2>
      <p>
        {lan == "bangla"
          ? "আপনার মোবাইলে আসা ৪ ডিজিট এর ওটিপি কোডটি ব্যবহার করুন"
          : "Please Check your mobile sms to take 4 digit OTP code for continue"}
      </p>
      <div style={{ alignSelf: "center" }}>
        <OtpInput
          shouldAutoFocus={true}
          isInputNum={true}
          value={val}
          onChange={(otp) => setOTP(otp)}
          numInputs={4}
          separator={<span>-</span>}
          inputStyle={{
            width: "60px",
            height: "60px",
            margin: "0px 10px",
            fontSize: "18px",
            border: "1px solid Gray",
          }}
        />
      </div>
      <br />
      <br />
      {resend == true ? (
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => handleResendOTP()}
        >
          {lan == "bangla" ? "পুনরায় কোডটি পাঠান" : "Resend OTP"}
        </Button>
      ) : (
        <Countdown
          style={{ textAlign: "center" }}
          title={lan == "bangla" ? "পুনরায় কোডটি পাঠান" : "Resend"}
          size="small"
          value={deadline}
          onFinish={() => setResend(true)}
        />
      )}
      <br />

      {submit ? (
        <Button type="primary" onClick={submitOtp}>
          {lan == "bangla" ? "সাবমিট করুন" : "Submit"}
        </Button>
      ) : (
        <Button type="primary" disabled onClick={submitOtp}>
          {lan == "bangla" ? "সাবমিট করুন" : "Submit"}
        </Button>
      )}
    </ModalContainer>
  );
};

export default connect(null, {
  getPaymentOtp,
  confirmPaymentOTP,
  resendPaymentOtp,
})(Banking);
