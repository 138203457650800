import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox } from 'antd';
import { LoginContainer, FlexContainer } from './style';
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import {login} from '../../action/loginAction';
const Login = ({login}) => {
    const [lan, setLan] = useState('english');
    useEffect(() => {
        setLan(localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
        window.scrollTo(0, 0);
        
        if(localStorage.getItem('token')){
            history.push('/dashboard')
        }
    }, [])
    const onFinish = (values) => {
        login(values.mobile_number, values.password)
      };
    
      const onFinishFailed = (errorInfo) => {
      };
    return ( 
        <FlexContainer>
            <LoginContainer>
                <img src='./images/logo.png' />
                <p>{lan == 'bangla' ? 'লগ ইন করার মাধ্যমে আপনার একাউন্ট এ প্রবেশ করুন' : 'Please sign in to access your merchant account'}</p>
                <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{width: '100%'}}
                requiredMark={false}
                >
                <Form.Item
                    label={lan == 'bangla' ? 'মোবাইল নম্বার': 'Mobile Number'}
                    name="mobile_number"
                    rules={[
                    {
                        required: true,
                        message: 'Mobile number is required',
                    },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={lan == 'bangla' ? 'পাসওয়ার্ড' : "Password"}
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                {/* <Form.Item
                    name="remember"
                    valuePropName="checked"
                    style={{textAlign: 'center'}}
                >
                    <Checkbox>{lan == 'bangla' ? "পাসওয়ার্ড মনে রাখুন" : "Remember Me"}</Checkbox>
                </Form.Item> */}

                <Form.Item
                    style={{textAlign: 'center'}}   
                >
                    <Button type="primary" htmlType="submit">
                    {lan == 'bangla' ? "লগ ইন করুন" : "Login"}
                    </Button>
                </Form.Item>
                
                <p>{lan == 'bangla' ? "কোনো একাউন্ট নেই ? " : " Don't have any account ? "}
                    <Link to="/registration">{lan == 'bangla' ? "রেজিষ্ট্রেশন করুন" : "Register Now"}</Link>
                    </p>
                <p><Link to="/password-reset">{lan == 'bangla' ? "পাসওয়ার্ড ভুলে গিয়েছেন ?" : "Forgot password ?"}</Link></p> 
                </Form>
            </LoginContainer>
        </FlexContainer>
     );
}
 
export default connect(null,{login})(Login);