import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Upload, message, Table, Space, Select, Row, Col } from 'antd'; 
import FormItem from 'antd/lib/form/FormItem';
import { Container } from '../../styles/globalStyle';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import {getCoverageAreas} from '../../action/shop'
import {createBulkOrder,bulkFileDownloader} from '../../action/parcel'
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import './style.css'
import SampleCSV from './bahon_sample.xlsx'
const { Option } = Select;
const cityRange = new Map();

const type = [
  { label: "Select One", value: "" },
  { label: "Parcel", value: "Parcel" },
  { label: "Document", value: "Document" },
];
const BulkOrder = ({getCoverageAreas,createBulkOrder, bulkFileDownloader, location}) => {
  const [data, setData] = useState(null)
  const [coverageArea, setCoverageArea] = useState(null)
  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [allCity, setAllCity] = useState(null);
  const [allArea, setAllArea] = useState(null);
  const [storeId, setStoreId] = useState("");
  const [areaCode, setAreaCode] = useState(null);

 
  useEffect( async() => {
    const search = location.search;
    const shop_id = new URLSearchParams(search).get("id");
    setStoreId(shop_id);
    const res = await getCoverageAreas()
    setCoverageArea(res)
    areaBuilder(res.city);
  }, [])

    const city_ = async (event) => {
      const cityCode = event;
      if (cityCode === "-1") {
        return;
      }
      const cityName = event;
      if (cityRange.has(cityName)) {
        const areaName = cityRange.get(cityName);
        
        setAllArea([...areaName.keys()]);
      
        setCity(cityName);
      }
    };

    const sub_city = async (event) => {
      const subCode = event;
      if (subCode === "-1") {
        return;
      }
      const areaName = event;
      const cityName = city;
      const res = areaMatch(cityName, areaName);
      setAreaCode(res.areaCode);
     
      setSubCity(res.areaName);
    };

    const  areaBuilder = (coverageAreas) =>{
        try {
            
            for(let city = 0; city< coverageAreas.length; city++){
                for(let sub = 0; sub < coverageAreas[city].sub_city.length; sub++){
        
                    for(let thana = 0; thana < coverageAreas[city].sub_city[sub].thana.length; thana++){
        
                        if(cityRange.has(`${coverageAreas[city].sub_city[sub].name}`) ){
                            const range = cityRange
                            cityRange.get(`${coverageAreas[city].sub_city[sub].name}`).set(`${coverageAreas[city].sub_city[sub].thana[thana].name}`,`${city+1}-${sub+1}-${thana+1}-1`)
                          }else{
                            const range = cityRange
                            cityRange.set( `${coverageAreas[city].sub_city[sub].name}`, new Map([[`${coverageAreas[city].sub_city[sub].thana[thana].name}`, `${city+1}-${sub+1}-${thana+1}-1`]])  )
                        }
                    }
                }
        
            }
            
      setAllCity([...cityRange.keys()]);
        }catch(e){
            console.log(e)
        }  
    }

    const areaMatch = (cityName,areaName) => {

        if(cityRange.has(cityName)){
            const area = cityRange.get(cityName);
            if(area.has(areaName)){
                return {success: true, city: true, area: true , areaCode: area.get(areaName)}
            }else{
                return {success: false, city: true, area: false}
            }
        }else{
            return {success: false, city: false, area: false}
        }
    }

    const renameKeys = (obj, newKeys) => {
        const keyValues = Object.keys(obj).map(key => {
          const newKey = newKeys[key] || key;
          return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }
    const onImportExcel = file => {
        //  gets the uploaded file object 
        
        const { files } = file.target;
        //  the file is read through the filereader object 
        
        const fileReader = new FileReader();
        fileReader.onload = event => {
        
          try {
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: 'binary' });

                let xldata = [];
                xldata = xldata.concat(XLSX.utils.sheet_to_json(workbook.Sheets['Bahon_Bulk_Order']));
                
                console.log(workbook)
                console.log(xldata)
                const bulk = []
                xldata.map((item,id) => {
                    if(Object.keys(item).length > 5){
                        bulk.push(item)
                    }
                })
                const newKeys = {
                    'Invoice ID' : "merchant_order_id",
                    'Product Type*' : "product_type",
                    'Customer Name*' : "recipient_name",
                    'Customer Mobile Number*' : "recipient_mobile_number",
                    'Customer Address*' : "recipient_address",
                    'City*' : "city",
                    'Area*' : "area",
                    'Weight(kg)*' : "weight",
                    'Quantity*' : "quantity",
                    'Amount to Collect*' : "amount_to_collect",
                    'Item Description' : "item_description",
                }
                let d = []
                bulk.map((item,id) => {
                    d.push(renameKeys(item, newKeys))
                })
                
                d.map((item,id) => {
                    const res = areaMatch(item?.city, item?.area)
                    d[id]['merchant_order_id'] = item?.merchant_order_id?.toString();
                    d[id]['weight'] = Number(item?.weight)*1000;
                    d[id]['recipient_area_code'] = res?.areaCode;
                    const cityName = item?.city
                
                if(cityRange.has(cityName)){
                    const areaName = cityRange.get(cityName)
                    setAllArea([...areaName.keys()]);
                    // this.setState({testArea: [ ...areaName.keys()]})
                    for(let i = 0 ; id >= i ; i++){
                        d[id]['city'] = cityName
                    }
                }
                })
        
            setData(d)
            message.success('uploaded successfully ！')
        
            // console.log(data);
        
          } catch (e) {
        
            console.log(e)
            //  here you can throw a hint that the file type error is incorrect 
        
            message.error(' incorrect file type ！');
        
          }
        
        };
        
        //  open the file in binary mode 
        
        fileReader.readAsBinaryString(files[0]);
    }
        
        
    const onFinish = async (values) => {
      // console.log(values)
      values?.items?.map((item,i) => {
        const areaCode = areaMatch(item.city, item.area)  
        values.items[i].recipient_area_code = areaCode?.areaCode
        // delete values.items[i].city
        // delete values.items[i].area
        // console.log(areaCode);
      })
      // console.log(values)
      const res = await createBulkOrder(storeId, values?.items)
      if(res === 201){
        setData(null)
      }
    };

    //Create form fields based off how many items are in the order
    const a = []
    const itemInputs = data?.map((item,id) => {

      if(id === 0){
        if (cityRange.has(item.city)) {
          const areaName = cityRange.get(item.city);
          // setAllArea([...areaName.keys()]);
          a.push(...areaName.keys())
          // setCity(item.city);
        }
      }

      
      return {
        merchant_order_id: item.merchant_order_id,
        product_type: item.product_type,
        recipient_name: item.recipient_name,
        recipient_mobile_number: item.recipient_mobile_number,
        recipient_address: item.recipient_address,
        // city: item.recipient_area_code.split ('-')[0],
        // area: item.recipient_area_code.split('-')[3],
        city: item.city,
        area: item.area,
        amount_to_collect: item.amount_to_collect,
        quantity: item.quantity,
        weight: item.weight,
        item_description: item.item_description,
      };
    });
 
    return (  
      <Container>
        <Row gutter={16} style={{display: 'flex', justifyContent: 'space-between'}}>
        <Col xs={8} style={{alignSelf: 'center'}}>
          {data ?
            <Row gutter={16} style={{display: 'flex', justifyContent: 'center'}}>
              <Col xs={12}>
                <input disabled className='file-box' style={{width: '100%'}} onChange={onImportExcel} accept=".xlsx" type="file" placeholder='Upload your xlsx file '/>
              </Col>
              <Col xs={12} style={{alignSelf: 'center'}}>
                <Button size='large' onClick={() => setData(null) } >Cancel</Button>
              </Col>
            
            </Row>
          :
              <input className='file-box' style={{width: '50%'}} onChange={onImportExcel} accept=".xlsx" type="file" placeholder='Upload your xlsx file '/>
            
          }
        </Col>
        <Col xs={5} style={{alignSelf: 'center'}}></Col>
        <Col xs={4} style={{alignSelf: 'center'}}>
          <Button onClick={() => bulkFileDownloader()} type="primary" danger  icon={<DownloadOutlined />} size='large'>Download Sample File</Button>
          
          </Col>
        </Row>
          
        {data ? 
          <Form onFinish={onFinish}>
            
            <Row gutter={16} style={{margin: '20px 0px'}}>
              
                      <Col xs={2}>Order ID</Col>
              
                      <Col xs={2}>Order Type</Col>
              
                      <Col xs={2}>Customer's Name</Col>
              
                      <Col xs={3}>Customer's Number</Col>
              
                      <Col xs={3}>Customer's Address</Col>
              
                      <Col xs={2}>Customer's City</Col>
              
                      <Col xs={2}>Customer's Area</Col>
              
                      <Col xs={2}>Amount to Collect</Col>
              
                      <Col xs={2}>Quantity</Col>
              
                      <Col xs={2}>Weight</Col>
              
                      <Col xs={2}>Details</Col>
            </Row>

            <Form.List name="items" initialValue={itemInputs}>
              {(data, { add, remove }) => (
                <>
                  {data.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: "flex", marginBottom: 0 }}
                      align="center"
                    >
                      <Row gutter={16}>
                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "merchant_order_id"]}
                          fieldKey={[field.fieldKey, "merchant_order_id"]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "product_type"]}
                          fieldKey={[field.fieldKey, "product_type"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <Select
                              size="middle"
                              placeholder="Parcel Type"
                            >
                            {type?.map((item, id) => (
                              <Option key={id} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "recipient_name"]}
                          fieldKey={[field.fieldKey, "recipient_name"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "recipient_mobile_number"]}
                          fieldKey={[field.fieldKey, "recipient_mobile_number"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "recipient_address"]}
                          fieldKey={[field.fieldKey, "recipient_address"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "city"]}
                          fieldKey={[field.fieldKey, "city"]}
                          rules={[{required: true, message:"",},]}
                        >
                        <Select
                          size="middle"
                          placeholder="Select one city"
                          onChange={(e) => city_(e)}
                        >
                          <Option value="-1">Select One
                          </Option>
                          {allCity !== null
                            ? allCity?.map((item, id) => (
                                <Option key={id} value={item}>
                                  {item}
                                </Option>
                              ))
                            : null}
                        </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "area"]}
                          fieldKey={[field.fieldKey, "area"]}
                          rules={[{required: true, message:"",},]}
                        >
                        <Select
                          size="middle"
                          placeholder="Select one subcity"
                          onChange={(e) => sub_city(e)}
                        >
                          <Option value="-1">Select One
                          </Option>
                          {allArea !== null
                            ? allArea?.map((item, id) => (
                                <Option key={id} value={item}>
                                  {item}
                                </Option>
                              ))
                            : null}
                        </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "amount_to_collect"]}
                          fieldKey={[field.fieldKey, "amount_to_collect"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "quantity"]}
                          fieldKey={[field.fieldKey, "quantity"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "weight"]}
                          fieldKey={[field.fieldKey, "weight"]}
                          rules={[{required: true, message:"",},]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col xs={2}>
                        <Form.Item
                          {...field}
                          name={[field.name, "item_description"]}
                          fieldKey={[field.fieldKey, "item_description"]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Col>

                      </Row>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add More

                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item style={{textAlign: 'center'}}>
              <Button type="primary" htmlType="submit">
                Submit all orders
              </Button>
            </Form.Item>
          </Form>
        :null}
      </Container>
     );
}
 
export default connect(null,{getCoverageAreas,createBulkOrder,bulkFileDownloader})(BulkOrder);