import { ERROR_MESSAGE, LOADED, LOADING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./type"
import api from './apis';
import history from "../history";

// =======Create Shops===========
export const getCoverageAreas= () => async (dispatch) => {
  //  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get("/get-area");
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE ,
      payload: err.response ? err.response.data.message : history.push('/opps'),
    });
  }
}

// =======Create Shops===========
export const createStore = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const obj = {
    "name" : data.name,
    "email": data.email,
    "address": data.address,
    "mobile_number" : data.mobile_number,
    "pickup_area_code": data.city +'-'+ data.subCity +'-'+ data.thana +'-'+ data.area,
    "pickup_address": data.pickup_address,
    "pickup_type": data.pickup_type,
    "trade_license":data.trade_license
}
  try {

    const response = await api(localStorage.getItem("token")).post("/create-shop", {...obj});
   
    if (response.status === 201 && response.data !== "") {
      const status = response.status;
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE ,  payload: 'Your store has been created successfully'});
        return status;
    }else{
      dispatch({ type: LOADED });
    }
  } catch (err) {
    
    if(err?.response?.status === 422){
      err.response.data.errors ?
      err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
        :
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : null ,
        });
      }

    if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message :  history.push('/opps'),
    });
  }
};

//===========Update Shop======
export const updateStore = (datas) => async (dispatch) => {
  dispatch({ type: LOADING });
  // const id = datas.shop_id;
  const obj ={
    "shop_id" : datas.shop_id,
    "name" : datas.name,
    "active" : datas.active,
    "address": datas.address,
    "mobile_number" : datas.mobile_number,
    "pickup_area_code": datas.city +'-'+ datas.subCity +'-'+ datas.thana +'-'+ datas.area,
    "pickup_address": datas.pickup_address,
    "pickup_type": datas.pickup_type,
    "trade_license":datas.trade_license
  }

  let shops = null;
  try {
    const response = await api(localStorage.getItem("token")).patch("/update-shop", {...obj});

    if (response.status === 201) {
        shops = await api(localStorage.getItem("token")).get(`/get-shops`)
      
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: "Shop is updated successfully" });
        return response.status
    }
    else{
      
    dispatch({ type: LOADED });
    }
  } catch (err) {
    
    if(err?.response?.status === 422){
      err.response.data.errors ?
      err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
        :
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : null ,
        });
      }

    if(err.response)
    {
      dispatch( {type: LOADED });
        if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
    }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/opps'),
    });
  }
};


// =======Fetch Shops===========
export const fetchShops = () => async (dispatch) => {
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get('/get-shops');
        if(response.status === 200 ) {
            dispatch({ type: LOADED });
            return response.data;
        }
    }catch (err) {
        dispatch({ type: LOADED });
        if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : history.push('/opps'),
        });
    }
}

// ==========Fetch Shop by id=======
export const fetchStore = (id) => async (dispatch) => {
  // dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(`/get-shop?shop_id=${id}`);
   
    if(response.status===200){
      dispatch( {type: LOADED });
      return response.data;
    }
   
  } catch (err) {
    if(err.response)
    {
      dispatch( {type: LOADED });
        if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
    }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push('/opps'),
    });
  }
};
//=========== Statistics===========

export const getStoreStatictics = (id) => async (dispatch) => {
    // dispatch({type: LOADING});
    
    try{
      const response = await api (localStorage.getItem("token")).get(`/shop-statistic?shop_id=${id}`);
      
      if(response.status === 200 ) {
        
        // dispatch({ type: LOADED});
        return response.data;
      }
     
      
    } catch (err) {
      if(err.response)
      {
        // dispatch( {type: LOADED });
          if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          
      }
      // dispatch( {type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push('/opps'),
      });
    }
    // dispatch({ type: LOADED});
  }

  
// ============check unique=======
export const uniqueShopName = (value) => async (dispatch) => {

  try {
    const response = await api(localStorage.getItem("token")).get(`/unique-shop?shop_name=${value}`);

    if (response.status === 200) {
      return response.data.unique;
      
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/opps'),
    });
  }
};

// ============check unique while update=======
export const uniqueShopNameUpdate = (name, id) => async (dispatch) => {

  try {
    const response = await api(localStorage.getItem("token")).get(`/unique-shop?shop_name=${name}&&shop_id=${id}`);

    if (response.status === 200) {
      return response.data.unique;
      
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
      if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/opps'),
    });
  }
};

  // ==========Fetch Shop income=======
export const fetchShopIncome = (id) => async (dispatch) => {
  try {
    const response = await api(localStorage.getItem("token")).get(`/shop-income?shop_id=${id}`);
   
    if(response.status===200){
      dispatch( {type: LOADED });
      return response.data.totalIncome;
    }
   
  } catch (err) {
    if(err.response)
    {
      dispatch( {type: LOADED });
        if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
    }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push('/opps'),
    });
  }
};
// ==========Fetch Total income=======
export const fetchTotalIncome = () => async (dispatch) => {
  // dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(`/total-income`);
    
    if(response.status===200){
      // dispatch( {type: LOADED });
      return response.data.totalIncome;
    }
    
  } catch (err) {
    if(err.response)
    {
      // dispatch( {type: LOADED });
        if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
    }
    // dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push('/opps'),
    });
  }
};