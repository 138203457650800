import { Form, Input, Tabs, Button, Modal,Radio } from 'antd';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Container } from '../../styles/globalStyle';
import {profileInfo,updateProfileInfo,changePass,confirmChangePass,resendPassOtp,changeLanguage} from '../../action/setting';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import Countdown from 'antd/lib/statistic/Countdown';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Bangla from './bangla.png'
import English from './english.png'
import {notificationCount} from "../../action/support";
const { TabPane } = Tabs;
const Wrapper = styled.div`
/* display: flex;
justify-content: center;
flex-direction: column; */
align-self: center;
box-shadow: 0px 15px 41px -16px rgba(0,0,0,0.61);
/* padding: 20px; */
min-height: 51vh;
width: 50%;
padding: 40px 10px;
h1{
    font-size: 24px;
}
form{
    padding: 0px 50px;
    
}
@media only screen and (max-width: 992px) {
    width: 100%;
    padding: 40px 5px;
h1{
    font-size: 18px;
}
form{
    padding: 0px 5px 0px 0px;
    
}

}
`;
const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: auto;
    max-height: auto;
    img{
        width: 150px;
        align-self: center;
        margin-bottom: 10px;
    }
    h2{
        text-align: center;
        font-weight: 600;
    }
    p{
        text-align: center;
        font-weight: 600;
        color: gray;
    }
    button{
        text-align: center;
    }
    
`;
const Setting = ({profileInfo,updateProfileInfo,changePass,confirmChangePass,resendPassOtp,changeLanguage,notificationCount}) => {
  const [lan, setLan] = useState("english");
  const [data, setData] = useState([])
    const [submit, setSubmit] = useState(false)
    const [resend, setResend] = useState(false)
    const [show, setShow] = useState(false)
    const [val, setVal] = useState('');
    const [mobile, setMobile] = useState('')
    const [logout, setLogout] = useState(false)
    const [form] = Form.useForm();
    useEffect( async () => {
        setLan(
          localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
        );
        window.scrollTo(0, 0);
        notificationCount()
        const res =  await profileInfo();
    //   if(res){
    //     setData(res)
    //   }
      
    form.setFieldsValue({
        name: res?.name,
        mobile_number: res?.mobile_number,
        email: res?.email,
        address: res?.address,
    })
    
    }, [])
    const refresh = async () => {
        const res =  await profileInfo();
        //   if(res){
        //     setData(res)
        //   }
          
        form.setFieldsValue({
            name: res?.name,
            mobile_number: res?.mobile_number,
            email: res?.email,
            address: res?.address,
        })
    }
    
    const onProfileFinish = async (values) => {
        const res = await updateProfileInfo(values)
        if(res === 201 ){
            refresh()
        }
    };
    
    const onPasswordFinish = async (values) => {
        const res = await changePass(values)
        if(res === 201 ){
            setShow(!show)
            form.resetFields()
        }
    };
    const onLanguageFinish = async (values) => {
        // const res = await updatePassword(values)
        // if(res === 201 ){
        //     reFresh()
        // }
    };
    
    const setOTP = async (e) => {
        setVal(e)
        if(e.length > 3 ){
            setSubmit(true)
        }
        else{
            setSubmit(false)
        }
      }
    const submitOtp = async (e) => {
        const res = await confirmChangePass(val, logout)
        setVal('')
        if(res === 201){
            setShow(false)
        }
    }      

    const handleResendOTP = async () => {
        const res = await resendPassOtp()
        setVal('')
        
        setSubmit(false)
        setResend(false)
    }
    const onFinishFailed = (v) => (
        console.log(v)
    )
    const onLanguageChange = async (e) => {
        const res =  await changeLanguage(e.target.value)
    }
    const deadline = Date.now() + 1000 * 90;
    const settingForm = () => (
        <Wrapper style={{backgroundColor: '#FFF'}}>
            <Tabs tabPosition='left' size="small" >
                <TabPane tab={lan === "bangla" ? "প্রোফাইল আপডেট" : "Update Profile"} key="1" >
                    <h1 style={{textAlign: 'center'}}>{lan === "bangla" ? "প্রোফাইল আপডেট করুন" : "Update Profile"}</h1>
                    <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onProfileFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // style={{width: '70%', margin: 'auto'}}
                    form={form}
                    >
                    <Form.Item
                        label={lan === "bangla" ? "নাম" : "Name"}
                        name="name"
                        rules={[
                        {
                            required: true,
                            message: 'This field is required!',
                        }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {/* <Form.Item
                        label={lan === "bangla" ? "মোবাইল নাম্বার" : "Mobile Number"}
                        name="mobile_number"
                        
                        rules={[
                        {
                            required: true,
                            message: 'This field is required!',
                        },
                        // {
                        //     pattern: new RegExp(/^(01)[0-9][0-9]{8}$/),
                        //     message: 'Please enter a valid mobile number'
                        // }
                        ]}
                    >
                        <Input disabled />
                    </Form.Item> */}
                    <Form.Item
                        label={lan === "bangla" ? "ইমেইল" : "Email"}
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: 'This field is required!',
                        },
                        {
                            pattern: new RegExp(/\S+@\S+\.\S+/),
                            message: 'Please enter a valid email'
                        }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    
                    <Form.Item
                        name="address"
                        label={lan === "bangla" ? "ঠিকানা" : "Address"}
                        rules={[
                        {
                            required: true,
                            message: 'This field is required!',
                        },
                    ]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                        offset: 8,
                        span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                    </Form>
                </TabPane>

                <TabPane tab={lan === "bangla" ? "পাসওয়ার্ড আপডেট" : "Update Password"} key="3">
                    <h1 style={{textAlign: 'center'}}>{lan === "bangla" ? "পাসওয়ার্ড আপডেট করুন" : "Update Password"}</h1>
                    <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onPasswordFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    >
                    <Form.Item
                        label="New Password"
                        name="new_password"
                        rules={[
                        {
                            required: true,
                            message: 'This field is required!',
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Old Password"
                        name="old_password"
                        rules={[
                        {
                            required: true,
                            message: 'This field is required!',
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item
                        wrapperCol={{
                        offset: 8,
                        span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                    </Form>
                </TabPane>
                
                <TabPane tab={lan === "bangla" ? "ভাষা পরিবর্তন" : "Change Language"} key="2">
                    <h1 style={{textAlign: 'center'}}>{lan === "bangla" ? "ভাষা পরিবর্তন করুন" : "Change Language"}</h1>
                    <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onLanguageFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    style={{textAlign: 'center'}}
                    >
                    
                    <br />
                    <Radio.Group onChange={onLanguageChange}  size="large" defaultValue={localStorage.getItem('lan') === 'bangla' ? localStorage.getItem('lan') : 'english'}>
                        <Radio.Button value="bangla"><img style={{width: '25px'}} src={Bangla} /> বাংলা</Radio.Button>
                        <Radio.Button value="english"><img style={{width: '30px'}} src={English} /> English</Radio.Button>
                    </Radio.Group>
                    <br />
                    <br />
                    <br />
                    {/* <Form.Item >
                        <Button type="primary" htmlType="submit">
                        {localStorage.getItem('lan') === 'bangla' ? 'পরিবর্তন করুন' : 'Change'}
                        </Button>
                    </Form.Item> */}
                    </Form>
                </TabPane>
            </Tabs>
        </Wrapper>
    )
    return ( 
        <Container>

            {settingForm()}
            <Modal title={lan == 'bangla' ? 'ওটিপি ভেরিফিকেশন' : 'OTP Verification'} visible={show} onOk={() => setShow(false)} onCancel={() => setShow(false)}>
                <ModalContainer>
                    <img src="./images/logo.png" />
                    <h2>{lan == 'bangla' ? 'ভেরিফাই করুন' : 'Verify OTP'}</h2>
                    <p>
                        {lan == 'bangla' ? 'আপনার মোবাইলে আসা ৪ ডিজিট এর ওটিপি কোডটি ব্যবহার করুন' : 'Please Check your mobile sms to take 4 digit OTP code for continue'}
                    </p>
                    <div  style={{alignSelf: 'center'}}>
                        
                        <OtpInput
                            shouldAutoFocus={true}
                            isInputNum={true}
                            value={val}
                            onChange={(otp) => setOTP(otp)}
                            numInputs={4}
                            separator={<span>-</span>}
                            inputStyle={{width: '60px', height: '60px', margin: '0px 10px', fontSize: '18px', border: '1px solid Gray'}}
                        />
                        
                    </div>
                    <br />
                    <br />
                    {resend == true ? 
                            <Button type="primary" htmlType="submit" onClick={() =>handleResendOTP() }>
                                {lan == 'bangla' ? 'পুনরায় কোডটি পাঠান' : 'Resend OTP'}
                            </Button>
                        :
                        <Countdown style={{textAlign: 'center'}} title={lan == 'bangla' ? 'পুনরায় কোডটি পাঠান' : 'Resend'} size="small" value={deadline} onFinish={() => setResend(true)} /> }
                       <br /> 
                     
                    {val.length > 3 ? 
                    <>
                                       
                        <Form.Item name="remember"  onChange = {() => setLogout(!logout)}>
                            <Checkbox value={logout}>Do you want to logout from other devices</Checkbox>
                        </Form.Item>
                        <Button type='primary' onClick={submitOtp}>{lan == 'bangla' ? 'সাবমিট করুন' : 'Submit'}</Button>

                    </>
                    

                    : 
                    <>
                       
                        <Form.Item name="remember"  onChange = {() => setLogout(!logout)}>
                            <Checkbox value={logout} disabled>DO you want to logout from other devices</Checkbox>
                        </Form.Item>
                        
                    <Button type='primary' disabled onClick={submitOtp}>{lan == 'bangla' ? 'সাবমিট করুন' : 'Submit'}</Button>
                    </>
                    }
                               
                   
                          
                          
                            
                </ModalContainer>
            </Modal>

        </Container>
     );
}
 
export default connect(null,{profileInfo,updateProfileInfo,changePass,confirmChangePass,resendPassOtp,notificationCount,changeLanguage})(Setting);