import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import Registration from "../pages/registration";
import ParcelList from "../pages/parcel";
import ParcelDetails from "../pages/parcel/parcelDetails";
import StoreList from "../pages/store";
import StoreDetails from "../pages/store/storeDetails";
import CreateParcel from "../pages/parcel/createParcel";
import EditParcel from "../pages/parcel/editParcel";
import Setting from "../pages/setting";
import TransactionList from "../pages/transaction";
import ForgotPasswordverification from "../pages/login/forgotPasswordverification";
import BulkOrder from "../pages/parcel/createBulk";
import supportList from "../pages/support/supportList";
import SupportDetails from "../pages/support/supportDetails";
import Agreement from "../pages/registration/agreement";
const Switcher = () => {
  return (
    <Switch>
      <Route path="/password-reset" component={ForgotPasswordverification} />
      <Route path="/login" component={Login} />
      <Route path="/terms" component={Agreement} />
      <Route path="/registration" component={Registration} />
      <Route path="/ticket-details" component={SupportDetails} />
      <Route path="/support" component={supportList} />
      <Route path="/setting" component={Setting} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/transaction" component={TransactionList} />
      <Route path="/parcel" component={ParcelList} />
      <Route path="/parcel-create" component={CreateParcel} />
      <Route path="/parcel-edit" component={EditParcel} />
      <Route path="/parcel-bulk-input" component={BulkOrder} />
      <Route path="/parcel-details" component={ParcelDetails} />
      <Route path="/store" component={StoreList} />
      <Route path="/store-details" component={StoreDetails} />
      <Redirect from="/" exact to="/dashboard" />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Switcher;
