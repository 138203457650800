
import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox } from 'antd';
import { LoginContainer, FlexContainer } from './style';
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import {forgetPass,resendForgotOtp,getOTP} from '../../action/loginAction';
import Countdown from 'antd/lib/statistic/Countdown';
const PasswordReset = ({forgetPass,resendForgotOtp,getOTP,location,mobile_number}) => {
    const [otp, setOtp] = useState(false)
    const [resend, setResend] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [lan, setLan] = useState('english');
    useEffect( async () => {
       
        setLan( localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
    }, [])
    const onFinish = async (values) => {
        // console.log(getEmail)
        const res = forgetPass(mobile,values.otp, values.password, values.confirm_password)
        if(res === 201){
            history.push('/login')
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      
      const handleResendOTP = async () => {
        const res = await resendForgotOtp(mobile)
        if(res){
            setResend(false)
        }
       }
    
       const getOtp = async (value) => {
           const res = await getOTP(value?.mobile)
           if(res){
                setMobile(value?.mobile)
                setOtp(true)
           }
       }

    const deadline = Date.now() + 1000 * 90;
    return ( 
        <>
        {otp ?
            <Form
            name="basic"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{width: '100%'}}
            requiredMark={false}
            >
            

            <Form.Item
                label="Enter OTP"
                name="otp"
                rules={[
                {
                    required: true,
                    message: 'Please input OTP',
                },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="New Password"
                name="password"
                rules={[
                {
                    required: true,
                    message: 'Please input your new password!',
                },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                {
                    required: true,
                    message: 'Please confirm password!',
                },
                ]}
            >
                <Input.Password />
            </Form.Item>


            <Form.Item
                style={{textAlign: 'center'}}
            >
                <Button type="primary" htmlType="submit">
                    Reset password
                </Button>
                {resend == true ? 
                        <Button type="primary" onClick={() =>handleResendOTP() }>
                            {lan == 'bangla' ? 'পুনরায় কোডটি পাঠান' : 'Resend OTP'}
                        </Button>
                    :
                    <Countdown style={{textAlign: 'center'}} title={lan == 'bangla' ? 'পুনরায় কোডটি পাঠান' : 'Resend'} size="small" value={deadline} onFinish={() => setResend(true)} /> 
                }
            </Form.Item>
            <p>Don't have any account ? <Link to="/registration">Register Now</Link></p>
            <p><Link to="/login">Back to Login</Link></p>
            </Form>
            :
            
            <Form
            name="basic"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={getOtp}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{width: '100%'}}
            requiredMark={false}
            >
            

            <Form.Item
                label="Enter registered mobile number"
                name="mobile"
                rules={[
                {
                    required: true,
                    message: 'Please input your mobile number!',
                },
                ]}
            >
                <Input />
            </Form.Item>




            <Form.Item
                style={{textAlign: 'center'}}
            >
                <Button type="primary" htmlType="submit">
                   Get OTP
                </Button>
                
            </Form.Item>
            <p>Don't have any account ? <Link to="/registration">Register Now</Link></p>
            <p><Link to="/login">Back to Login</Link></p>
            </Form>
        }
        </>
     );
}
 
export default connect(null,{forgetPass,resendForgotOtp,getOTP})(PasswordReset);