import api from "./apis";
import history from "../history";
import { ERROR_MESSAGE, LOADED, LOADING, VALIDATION_ERROR,RESET_MESSAGES, SUCCESS_MESSAGE } from "./type";

// ==========Sign up==
export const signUp = (data) => async (dispatch) => {
  
    dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).post("/signup", {...data});
      
      if (response.status === 201) {
        
        dispatch({ type: LOADED });
        return response.status;
      }
    } catch (err) {
      
        
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }

       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
          }
      dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/opps'),
      });
    }
  };
  // ===========Verify====
export const verifySignUp = (mobile_number, OTP) => async (dispatch) => {
    const obj = {
        "mobile_number": mobile_number,
        "OTP": OTP
    }
    // dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).put("/verify-signup", {...obj});
  
      if (response.status === 201) {
        
        dispatch({ type: LOADED });
        // return response.status;
            // {response.data?.step ? localStorage.setItem("unauth", response.data?.step) : null};
            if(response.data?.step){
                localStorage.setItem("unauth", response.data?.step)
            }
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("name", response.data.name);
            dispatch({ type: LOADED });
            dispatch({ type: RESET_MESSAGES });
            history.replace('/dashboard');
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      
        if(err?.response?.status === 422){
          err.response.data.errors ?
          err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
            :
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response ? err.response.data.message : null ,
            });
          }

       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
          }
      dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/opps'),
      });
    }
  };

  // ========== Resend otp=========
  export const resendRegOtp = (mobile_number) => async (dispatch) => {
   
    // dispatch({ type: LOADING });
    const obj = {
      'type': 'signup',
      'mobile_number': mobile_number,
    }
    try {
      const response = await api(localStorage.getItem("token")).patch("/resend-otp", {...obj});
  
      if (response.status === 201) {
        
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: 'OTP is send to your number' });
        
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }

       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
          }
      dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/opps'),
      });
    }
  };

  // ==============Complete Profile========
  export const completeRegistration = (formValues,docImageFront,docImageBack) => async (dispatch) => {
    
    try {
      const doc_image_front = docImageFront;
      const doc_image_back = docImageBack;
  
      dispatch({ type: LOADING });
      const data = new FormData();
      const tk = localStorage.getItem('token');
  
      data.append("address", formValues.address);
      data.append("merchant_type", formValues.merchant_type);
      data.append("doc_type", formValues.doc_type);
      if(formValues.merchant_type === 'Company'){
        data.append("company_name", formValues.company_name);
      }
      if(formValues.doc_type !== 'NONE'){
        data.append("doc_no", formValues.doc_no);
        data.append("doc_image_front", doc_image_front);
        data.append("doc_image_back", doc_image_back);
      }
      const response = await api().put("/complete-profile", data,{
        headers: {
          "content-type": "multipart/form-data",
          'authorization': `Bearer ${tk}`
        },
      });
  
      if (response.status === 201) {
        // dispatch({ type: SUCCESS_MESSAGE, payload: response.data });
        dispatch({ type: LOADED });
        // history.push("/otp");
        localStorage.removeItem('unauth')
        history.push("/dashboard");
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
      
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }

      if(err.response)
          {
              dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
      // dispatch({
      //   type: NETWORK_ERROR,
      //   payload: err.message ? "Request failed try again." : null,
      // });
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({ type: LOADED });
      // history.push("/registration");
    }
  };

  // ============check unique=======
    export const checkUnique = (value) => async (dispatch) => {
   
      try {
        const response = await api(localStorage.getItem("token")).get(`/check-unique?mobile_number=${value}`);
    
        if (response.status === 200) {
          return response.data.unique;
          
        } else {
          dispatch({ type: LOADED });
        }
      } catch (err) {
        
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }

         if(err.response)
            {
              dispatch( {type: LOADED });
                if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
            }
        dispatch({ type: LOADED });
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : history.push('/opps'),
        });
      }
    };