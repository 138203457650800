import styled from "styled-components";

export const Container = styled.div`
    padding: 15px;
    /* position:  ${props => props.height > 30 ? 'fixed' : 'relative'}; */
    position: fixed;
    display: flex;
    justify-content: center;
    top: 4px;
    height: 80px;
    width: 100%;
    z-index: 100;
    background-color: #FFF ;
    box-shadow: 1px 15px 25px -9px rgba(0,0,0,0.32) ;
    @media only screen and (max-width: 992px) {
        background-color: #fff;
        box-shadow: 1px 15px 25px -9px rgba(0,0,0,0.32);
        justify-content: space-around;
    }
    transition: all 0.3s;
`;

export const Navbar = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 10px 0px;
    
   
`

export const RNavbar = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 10px;
    display: ${props => props.show ? 'block' : 'none'};
    @media only screen and (max-width: 992px) {
        width: 100%;
        height: 100vh; 
        position: fixed;
        right: 0;
        top: 65px;
        /* overflow: hidden; */
        /*  */
        /* position: fixed; */
        /* height: 80%; */
        background-color: #FFF;
        z-index: 100;
        text-align: center;
    }
`

export const NavItem = styled.li`
    display: inline;
    color:#2A2A2A;
    margin: 0rem;
    padding: 1rem;
    font-size: 16px;
    border-bottom:${props => props.active === true ? '4px red solid' : 'none'} ;
    cursor: pointer;
    :hover{
        border-bottom: 4px red solid ;
        transition-duration: 0.1s;
        /* border-radius: 10px 0px ; */
    }
    @media only screen and (max-width: 992px) {
        display: block;
    }
`

export const TButton = styled.button`
        background-color: #ED1C24;
        display: inline-block;
        padding: 10px 12px;
        border-radius: 10px;
        color: #fff;
        text-transform: uppercase;
        font-size: 0.7rem;
        letter-spacing: .15rem;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: none;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #E1403D;
            border-radius: 10px;
            z-index: -2;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: #ED1C24;
            transition: all .3s;
            border-radius: 10px;
            z-index: -1;
        }
        &:hover {
            color: #FFF;
            text-decoration: none;
            &:before {
            width: 100%;
            }
        }
`
export const Buttons = styled.div`
    /* position: absolute;
    right: 10; */
`;
