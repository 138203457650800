import { CheckSquareOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {fetchStore,getStoreStatictics} from '../../action/shop';
import { Card, Container, HeaderText, SubHeaderText } from '../../styles/globalStyle';
import AddPaymentMethod from './addPaymentMethod';
import EditStore from './editStore';
const StoreDetails = ({location,fetchStore,getStoreStatictics}) => {
    window.scrollTo(0, 0);
    const [lan, setLan] = useState('english');
    const [data, setData] = useState(null);
    const [statistic, setStatistic] = useState(null);
    const [shopId, setShopId] = useState(null);
    const [editForm, setEditForm] = useState(false);
    const [formShow, setFormShow] = useState(false);
    useEffect( async () => {
        setLan(localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
        const search = location.search;
        const shop_id = new URLSearchParams(search).get("shop_id");
        setShopId(shop_id)
        const shops = await fetchStore(shop_id)
        setData(shops)
        const statis = await getStoreStatictics(shop_id)
        setStatistic(statis)
    }, []);
    const handleRefresh = async () => {
        const shops = await fetchStore(shopId)
        setData(shops)
        const statis = await getStoreStatictics(shopId)
        setStatistic(statis)
    }
    return ( 
        
        <Container name="detail">
            <Row gutter={32}>
                <Col xs={24}>
                    <HeaderText>{lan === 'bangla' ? 'স্টোরের ডিটেইলস' : 'Store Details'}</HeaderText>
                </Col>
                {data?.payment_info === null ? 
                <Col xs={24}>
                    <Tag onClick={() => setFormShow(true)} color="red" style={{width: '100%', padding: '10px', fontWeight: '550', textAlign: 'center', fontSize:'16px', cursor: 'pointer'}}>{lan === 'bangla' ? '***স্টোরের পেমেন্ট এর পদ্ধতি যুক্ত করা হয় নি, পেমেন্ট পদ্ধতি যুক্ত করন***' : '***This shop has no payment method, please add payment method***'}</Tag>
                    
                </Col>
                :
                    null
                }
                <Col xs={24} sm={12}>
                    <Card style={{minHeight: '410px'}}>
                        <SubHeaderText>{lan === 'bangla' ? 'স্টোরের পরিসংখ্যান' : 'Statistics'}</SubHeaderText>
                        <Row gutter={16}>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'একাউন্ট ব্যালেন্স': 'Account Balance:'}</p></Col><Col xs={12} >{statistic?.account_balance} &#2547;</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ব্যালেন্স উত্তোলন এর পরিমাণ': 'Total Withdraw:'}</p></Col><Col xs={12} >{statistic?.total_withdraw} &#2547;</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ডেলিভারী পেন্ডিং আছে': 'Pending Delivery:'}</p></Col><Col xs={12} >{statistic?.pending_delivery}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'রিটার্ন পেন্ডিং আছে ': 'Pending Return:'}</p></Col><Col xs={12} >{statistic?.pending_return}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'সর্বোমোট ডেলিভারী হয়েছে': 'Total Delivered:'}</p></Col><Col xs={12} >{statistic?.total_delivered}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'সর্বোমোট পার্সেল এসেছে': 'Total Parcel:'}</p></Col><Col xs={12} >{statistic?.total_order}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'সর্বোমোট রিটার্ন হয়েছে': 'Total Returned:'}</p></Col><Col xs={12} >{statistic?.total_returned}</Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={24} sm={12}>
                    <Card style={{minHeight: '410px'}}>
                        <SubHeaderText>{lan === 'bangla' ? 'সাধারণ ইনফরমেশন' : 'General Information'} &nbsp;&nbsp;
                        <Tooltip title={lan === 'bangla' ? 'স্টোর এর ইনফরমেশন আপডেট করুন' : "Change Shop Information"} onClick={() => setEditForm(true)}>
                                            <Button type="primary" shape="sqaure" icon={<EditOutlined />} />
                                        </Tooltip>
                        </SubHeaderText>
                        <Row gutter={16}>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'স্টোরের আইডি': 'ID:'}</p></Col><Col xs={12} >{data?.shop_id}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'স্টোরের নাম': 'Name:'}</p></Col><Col xs={12} >{data?.name}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'স্টোরের মোবাইল': 'Mobile:'}</p></Col><Col xs={12} >{data?.mobile_number}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'স্টোরের ইমেইল': 'Email:'}</p></Col><Col xs={12} >{data?.email}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'স্টোরের ঠিকানা': 'Address:'}</p></Col><Col xs={12} >{data?.address}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'ট্রেড লাইসেন্স': 'Trade License:'}</p></Col><Col xs={12} >{data?.trade_license ? data?.trade_license : ''}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'পিকা-আপ এর ঠিকানা': 'Pickup Address:'}</p></Col><Col xs={12} >{data?.pickup_address}</Col>
                            <Col xs={12} ><p>{lan === 'bangla' ? 'পার্সেলের ধরণ': 'Pickup Type:'}</p></Col><Col xs={12} >{data?.pickup_type}</Col>
                        </Row>
                    </Card>
                </Col>
                
                {data?.payment_info ? 
                    <Col xs={24} sm={12}>
                        <Card  style={{minHeight: '410px'}}>
                            
                                <> 
                                    <SubHeaderText>{lan === 'bangla' ? 'পেমেন্ট ইনফরমেশন' : 'Payment Information'} &nbsp;&nbsp;
                                        <Tooltip title={lan === 'bangla' ? 'স্টোর এর পেমেন্ট ইনফরমেশন আপডেট করুন' : "Change Payment Method"} onClick={() => setFormShow(true)}>
                                            <Button type="primary" shape="sqaure" icon={<EditOutlined />} />
                                        </Tooltip>
                                    </SubHeaderText>
                                    {data?.payment_info?.payment_method === 'Bank Account' ?
                                    <Row gutter={16}>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'পেমেন্ট মেথড': 'Payment Method:'}</p></Col><Col xs={12} >{data?.payment_info?.payment_method}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'ব্যাংকের নাম': 'Service Name:'}</p></Col><Col xs={12} >{data?.payment_info?.bank_name}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'ব্রাঞ্চের নাম': 'Mobile Number:'}</p></Col><Col xs={12} >{data?.payment_info?.branch_name}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'একাউন্টের নাম': 'Email:'}</p></Col><Col xs={12} >{data?.payment_info?.account_name}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'একাউন্ট নাম্বার': 'Email:'}</p></Col><Col xs={12} >{data?.payment_info?.account_number}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'একাউন্ট টাইপ': 'Address:'}</p></Col><Col xs={12} >{data?.payment_info?.account_type}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'রাউটিং নাম্বার': 'Routing Number:'}</p></Col><Col xs={12} >{data?.payment_info?.routing_number}</Col>
                                    </Row>
                                    :
                                    <Row gutter={16}>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'পেমেন্ট মেথড': 'Payment Method:'}</p></Col><Col xs={12} >{data?.payment_info?.payment_method}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'সার্ভিস এর নাম': 'Service Name:'}</p></Col><Col xs={12} >{data?.payment_info?.mobile_bank}</Col>
                                        <Col xs={12} ><p>{lan === 'bangla' ? 'মোবাইল নাম্বার': 'Mobile Number:'}</p></Col><Col xs={12} >{data?.payment_info?.mobile_banking_account}</Col>
                                        
                                    </Row>
                            }
                                </>
                            
                        
                        </Card>
                    </Col>  
                :
                    <>
                    </>
                }

            </Row>
                {shopId ? <EditStore  shop_id={shopId} status={editForm} close={() => setEditForm(false)} refresh = {handleRefresh} re={true} />
                    : null}
                <AddPaymentMethod shop_id={shopId} refresh={handleRefresh} status={formShow} close = {() => setFormShow(false)} />
        </Container>
     );
}
 
export default connect(null, {fetchStore,getStoreStatictics})(StoreDetails);