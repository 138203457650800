import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import styled from "styled-components";
import {completeRegistration} from '../action/registrationAction'
const { Option } = Select;
const ConfirmWrapper = styled.div`
    border-radius: 5px;
    align-content: center;
    text-align: center;
    width: 600px;
    height: auto;
    margin: auto;
    background-color: #fff;
    box-shadow:  0 2px 4px 0 rgba(7, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
    position: relative;
    top:${(props=>props.status ? '0%' : '-1000px')};
    z-index: 10000;
    padding: 20px 90px;
    transition: 0.9s;
    h3{
        text-align: center;
        padding: 15px;
        color: #3AB569;
        font-size: 24px;
    }
    h4{
        text-align: center;
    }
    p{
        font-size: 14px;
        text-align: center;
    }
    @media only screen and (max-width: 600px) {
        top:${(props=>props.status ? '0%' : '-1000px')};
        
        width: 100%;
        height: auto;
        margin: 0px;
        z-index: 100000;
        padding: 15px 10px;
        font-size: 14px;
        h2{
            font-size: 13px;
        }
        p{
            font-size: 13px;
        }
        span{
            font-size: 11px;
        }
    }
    
`;
const mtype = [
    { label: "Company", value: "Company" },
    { label: "Individual", value: "Individual" },
  ];
const type = [
    { label: "NID", value: "NID" },
    { label: "PASSPORT", value: "PASSPORT" },
    { label: "Birth Certificate", value: "BC" },
    { label: "Not Now", value: "NONE" },
  ];
const Verification = ({status,completeRegistration}) => {
    
    const [lan, setLan] = useState("english");
    const [MrType, setMrType] = useState('');
    const [acType, setACType] = useState('');
    const [docImageFront, setDocImageFront] = useState('');
    const [docImageBack, setdocImageBack] = useState('');
    useEffect(() => {
        setLan(
        localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
        );
        
    }, [])

    const onFinish = async (values) => {
        const res = await completeRegistration(values,docImageFront,docImageBack)
        
    };
    
    const onFinishFailed = (errorInfo) => {
    };
    
    const handleImageChange = (e) => {
        let nam = e.target.name;
        let val = e.target.value;
            var type= e.target.files[0].type;
            if (e.target.files && e.target.files[0]) {
              var fsize = e.target.files[0].size/1024;
                if( type == 'image/jpg' || type == 'image/jpeg' || type == 'image/JPG' || type == 'image/JPEG' || type == 'image/PNG' || type == 'image/png' ){
                
                    try {
                        Resizer.imageFileResizer(
                        e.target.files[0],
                        300,
                        300,
                        'PNG',
                        100,
                        0,
                        uri => {
                            nam === 'doc_image_front' ? setDocImageFront(uri) : setdocImageBack(uri)
                        },
                        'blob',
                        200,
                        200,
                        );
                        }   
                        catch(err) {
                        }
                }
                else{
        
                    alert('Invalid image format');
                    return true;
                }
            }
        }
    return ( 
        <ConfirmWrapper status = {status} style={{transition: '0.7s'}}>
            <h2 style={{color: '#3AB569',}}>You are almost there</h2>
            <p>Please fill out the form carefully. </p>
            <Form
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{width: '100%'}}
                requiredMark={false}
                >
                    
                    <Row gutter={16}>
                        <Col sm={24}>
                            <Form.Item
                                label={lan == 'bangla' ? "ঠিকানা" : "Address"}
                                name="address"
                                rules={[
                                {
                                    required: true,
                                    message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        <Col sm={24}>
                            <Form.Item
                                label={lan == 'bangla' ? "একাউন্টের ধরণ" : "Account Type"}
                                name="merchant_type"
                                rules={[
                                {
                                    required: true,
                                    message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                },
                                ]}
                            >
                            <Select
                              size="middle"
                              onChange={(e)=>setMrType(e)}
                            >
                              {mtype?.map((item, id) => (
                                <Option key={id} value={item.value}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                            </Form.Item>
                        </Col>
                        {MrType === 'Company' ? 
                        <Col sm={24}>
                            <Form.Item
                                label={lan == 'bangla' ? "কোম্পানীর নাম" : "Company name"}
                                name="company_name"
                                rules={[
                                {
                                    required: true,
                                    message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        :null}
                        <Col sm={24}>
                            <Form.Item
                                label={lan == 'bangla' ? "ডকুমেন্টের ধরণ" : "Document Type"}
                                name="doc_type"
                                rules={[
                                {
                                    required: true,
                                    message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                },
                                ]}
                            >
                            <Select
                              size="middle"
                              onChange={(e)=>setACType(e)}
                            >
                              {type?.map((item, id) => (
                                <Option key={id} value={item.value}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                            </Form.Item>
                        </Col>
                        {acType === '' || acType === 'NONE' ? null: 
                            <>
                            {acType === 'NID' ?
                                <Col sm={24}>
                                <Form.Item
                                    label={lan == 'bangla' ? "কার্ডের নাম্বার" : "ID Card Number"}
                                    name="doc_no"
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    {
                                        pattern: new RegExp(/^([\d]{10}|[\d]{13}|[\d]{17})$/),
                                        message: lan == 'bangla' ? "আইডি নাম্বারটি সঠিন নয়" : "ID number is not valid",
                                    },
                                    
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                </Col>
                            : null}
                            {acType === 'PASSPORT' ?
                                <Col sm={24}>
                                <Form.Item
                                    label={lan == 'bangla' ? "কার্ডের নাম্বার" : "ID Card Number"}
                                    name="doc_no"
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    {
                                        pattern: new RegExp(/^([a-zA-Z][0-9]{8}|[a-zA-Z]{2}[0-9]{7})$/),
                                        message: lan == 'bangla' ? "আইডি নাম্বারটি সঠিন নয়" : "ID number is not valid",
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                </Col>
                            : null}
                            {acType === 'BC' ?
                            
                                <Col sm={24}>
                                    <Form.Item
                                        label={lan == 'bangla' ? "কার্ডের নাম্বার" : "ID Card Number"}
                                        name="doc_no"
                                        rules={[
                                        {
                                            required: true,
                                            message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                        },
                                        {
                                            pattern: new RegExp(/^[0-9]{17}$/),
                                            message: lan == 'bangla' ? "আইডি নাম্বারটি সঠিন নয়" : "ID number is not valid",
                                        },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col> 
                            : null}
                            
                                <Col sm={12}>
                                    <Form.Item
                                        label={lan == 'bangla' ? "কার্ডের সামনের ছবি" : "ID Card Front Face Image"}
                                        name="doc_image_front"
                                        rules={[
                                        {
                                            required: true,
                                            message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                        },
                                        ]}
                                    >
                                        <Input type="file" name="doc_image_front" onChange={(e)=>handleImageChange(e)} />
                                    </Form.Item>
                                </Col>
                                <Col sm={12}>
                                    <Form.Item
                                        label={lan == 'bangla' ? "কার্ডের পিছনের ছবি" : "ID Card Back Face Image"}
                                        name="doc_image_back"
                                        rules={[
                                        {
                                            required: true,
                                            message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                        },
                                        ]}
                                    >
                                        <Input type="file" name="doc_image_back" onChange={(e)=>handleImageChange(e)}/>
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                <Form.Item
                style={{textAlign: 'center'}}
                >
                    <Button type="primary" htmlType="submit" > {lan == 'bangla' ? "রেজিষ্ট্রেশন করুন" : "Register"} </Button>
                </Form.Item>
                
            </Form>
        </ConfirmWrapper>
     );
}
 
export default connect(null,{completeRegistration})(Verification);