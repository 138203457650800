import styled from "styled-components";

export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    max-height: auto;
    
`;
export const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: auto;
    max-height: auto;
    img{
        width: 150px;
        align-self: center;
        margin-bottom: 10px;
    }
    h2{
        text-align: center;
        font-weight: 600;
    }
    p{
        text-align: center;
        font-weight: 600;
        color: gray;
    }
    button{
        text-align: center;
    }
    
`;
export const RegisterContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 800px;
    height: auto;
    margin: 20px auto;
    box-shadow: 0px 2px 16px 1px rgba(0,0,0,0.2);
    padding: 50px 60px;
    border-radius: 10px;
    align-self: center;
    background-color: #fff;
    img{        
        width: 170px;
        align-self: center;
    }
    h1{
        text-align: center;
        padding: 20px 0px 10px 0px;
    }
    p{
        text-align: center;
        font-weight: 600;
    }
    
    @media only screen and (max-width: 992px) {
        padding: 50px 60px;
        width: 90%;
        img{
            width: 100px ;
        }
    }
`;