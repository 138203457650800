import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Input, Select } from "antd";
import { connect } from "react-redux";
import {
  getPaymentOtp,
  confirmPaymentOTP,
  resendPaymentOtp,
} from "../../action/paymentAction";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import Countdown from "antd/lib/statistic/Countdown";
const { Option } = Select;
const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: auto;
  max-height: auto;
  img {
    width: 150px;
    align-self: center;
    margin-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-weight: 600;
  }
  p {
    text-align: center;
    font-weight: 600;
    color: gray;
  }
  button {
    text-align: center;
  }
`;
const MFS = ({
  refresh,
  close,
  shop_id,
  getPaymentOtp,
  confirmPaymentOTP,
  resendPaymentOtp,
}) => {
  const [lan, setLan] = useState("english");
  const [val, setVal] = useState("");
  const [resend, setResend] = useState("");
  const [submit, setSubmit] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(async () => {
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
  }, []);
  const options = [
    { label: "Select One", value: "" },
    { label: "bKash", value: "bKash" },
    { label: "Rocket", value: "Rocket" },
    { label: "Nagad", value: "Nagad" },
  ];
  const onFinish = async (values) => {
    const preData = { ...values };
    preData["shop_id"] = shop_id;
    preData["payment_method"] = "Mobile Banking";
    const code = await getPaymentOtp(preData);
    if (code == 201) {
      setShow(true);
    }
  };
  const handleResendOTP = async () => {
    const res = await resendPaymentOtp(shop_id);
    setVal("");
    setResend(false);
  };

  const submitOtp = async () => {
    const res = await confirmPaymentOTP(shop_id, val);
    if (res === 201) {
      setVal("");
      refresh();
      close();
    } else {
      setVal("");
      setSubmit(false);
    }
  };

  const setOTP = async (e) => {
    setVal(e);
    if (e.length > 3) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  };

  const deadline = Date.now() + 1000 * 90;
  return (
    <>
      {!show ? (
        <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="mobile_bank"
                label={lan === "bangla" ? "এমএফএস নাম" : "MFS Name"}
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? "অত্যাবশ্যকীয়"
                        : "This field is required",
                  },
                ]}
              >
                <Select
                  placeholder={
                    lan === "bangla"
                      ? "একটি অপশন সিলেক্ট করুন"
                      : "Please select an option"
                  }
                >
                  {options?.map((item, id) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="mobile_banking_account"
                label={
                  lan === "bangla" ? "একাউন্ট এর নাম্বার" : "Account Number"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? "অত্যাবশ্যকীয়"
                        : "This field is required",
                  },
                  {
                      pattern: new RegExp(/^(01)[0-9][0-9]{8}$/),
                      message: lan == 'bangla' ? "মোবাইল নাম্বারটি সঠিন নয়" : "Mobile Number is not valid",
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                {lan === "bangla" ? "জমা দিন" : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <ModalContainer>
          <h2>
            {lan == "bangla" ? "ওটিপি ভেরিফাই করুন" : "Verify your account"}
          </h2>
          <p>
            {lan == "bangla"
              ? "আপনার মোবাইলে আসা ৪ ডিজিট এর ওটিপি কোডটি ব্যবহার করুন"
              : "Please Check your mobile sms to take 4 digit OTP code for continue"}
          </p>
          <div style={{ alignSelf: "center" }}>
            <OtpInput
              shouldAutoFocus={true}
              isInputNum={true}
              value={val}
              onChange={(otp) => setOTP(otp)}
              numInputs={4}
              separator={<span>-</span>}
              inputStyle={{
                width: "60px",
                height: "60px",
                margin: "0px 10px",
                fontSize: "18px",
                border: "1px solid Gray",
              }}
            />
          </div>
          <br />
          <br />
          {resend == true ? (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleResendOTP()}
            >
              {lan == "bangla" ? "পুনরায় কোডটি পাঠান" : "Resend OTP"}
            </Button>
          ) : (
            <Countdown
              style={{ textAlign: "center" }}
              title={lan == "bangla" ? "পুনরায় কোডটি পাঠান" : "Resend"}
              size="small"
              value={deadline}
              onFinish={() => setResend(true)}
            />
          )}
          <br />

          {submit ? (
            <Button type="primary" onClick={submitOtp}>
              {lan == "bangla" ? "সাবমিট করুন" : "Submit"}
            </Button>
          ) : (
            <Button type="primary" disabled onClick={submitOtp}>
              {lan == "bangla" ? "সাবমিট করুন" : "Submit"}
            </Button>
          )}
        </ModalContainer>
      )}
    </>
  );
};

export default connect(null, {
  getPaymentOtp,
  confirmPaymentOTP,
  resendPaymentOtp,
})(MFS);
