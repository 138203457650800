import api from "./apis";
import history from "../history";
import { ERROR_MESSAGE, LOADED, LOADING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./type";

// =======================================Profile============
export const profileInfo = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
     
      const response = await api(localStorage.getItem("token")).get("/profile-info");
      dispatch({ type: LOADED });
      return response.data;
    }  
    catch (err) {
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    }
  };
  // =======================Update Profile=====
  export const updateProfileInfo = (data) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      
      const response = await api(localStorage.getItem("token")).patch("/update-profile",  {...data});
      if(response.status === 201 ){
        localStorage.setItem('name', data.name)
        dispatch({ type: SUCCESS_MESSAGE, payload: "Profile successfully updated" });
        dispatch({ type: LOADED });
        return response.status
      }
      
    } catch (err) {
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    }
  };

  // ==============Change Pass===
  export const changePass = (data) => async (dispatch) => {
    dispatch({ type: LOADING });
    try {
      
      const response = await api(localStorage.getItem("token")).post("/update-password",  {...data});
      // dispatch({ type: SUCCESS_MESSAGE, payload: "Successfully Updated" });
      
        dispatch({ type: LOADED });
        return response.status;
      
     
    } 
    catch (err) {
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    }
  };

  export const confirmChangePass = (otp, logout) => async (dispatch) => {
    dispatch({ type: LOADING });
    const obj = {
      OTP: otp,
      session: logout
    }
    try {
    
      const response = await api(localStorage.getItem("token")).patch("/verify-password-update",  {...obj});
      
      localStorage.setItem("token", response.data.token);
      dispatch({ type: SUCCESS_MESSAGE, payload: "Password updated successfully" });
      dispatch({ type: LOADED });
      return response.status;
      // history.push('/')
    }  
    catch (err) {
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    }
  };

  export const resendPassOtp = () => async (dispatch) => {
   
    // dispatch({ type: LOADING });
    const obj = {
      'type': 'UP',
    }
    try {
      const response = await api(localStorage.getItem("token")).patch("/new-otp", {...obj});
  
      if (response.status === 201) {
        
        dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: 'OTP is send to your number' });
        
      } else {
        dispatch({ type: LOADED });
      }
    }  
    catch (err) {
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    }
  };

  // ==============Change Language===
  export const changeLanguage = (lan) => async (dispatch) => {
    const data = {
      language: lan
    }
    dispatch({ type: LOADING });
    try {
      
      const response = await api(localStorage.getItem("token")).patch("/language/update",  {...data});
      // dispatch({ type: SUCCESS_MESSAGE, payload: "Successfully Updated" });
      
        dispatch({ type: LOADED });
        if(response.status === 201){
          
          localStorage.setItem('lan',lan)
          window.location.reload()
        }
      
     
    }  
    catch (err) {
      if(err?.response?.status === 422){
        err.response.data.errors ?
        err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
          :
          dispatch({
            type: ERROR_MESSAGE,
            payload: err.response ? err.response.data.message : null ,
          });
        }
     if(err.response)
        {
          dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    }
  };