import styled, { createGlobalStyle } from "styled-components";

    const GlobalStyles = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        font-family: 'Mulish',Arial, Helvetica, sans-serif;
    }
    button{
        }

`;
export default GlobalStyles;


export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

export const Container = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${props => props.name === 'detail' ? '10px 150px' : '10px 20px'};
    
    @media only screen and (max-width: 600px) {
        
    padding: ${props => props.name === 'detail' ? '10px 20px' : '10px 20px'};
    }
`;

export const HeaderText = styled.h1`
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0px;
`;

export const SubHeaderText = styled.p`
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0px;
`;

export const Card = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
    margin: 15px 0px;
        h1{
            font-weight: 600 ;
            padding: 0px 10px ;
        }
        min-height: 350px;
        ul{
            padding: 10px ;
        }
        li{
            list-style-type: none;
            margin: 15px 0px ;
            border-left: 5px solid green ;
            padding: 0px 5px ;
        }
`;