import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCoverageAreas,uniqueShopName, createStore } from "../../action/shop";
const { Option } = Select;
const CreateStore = ({
  status,
  close,
  refresh,
  getCoverageAreas,
  createStore,
  uniqueShopName,
  re,
  shopFnc
}) => {
  const [coverageArea, setCoverageArea] = useState(null);
  const [lan, setLan] = useState("english");
  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);
  const [isUnique, setIsUnique] = useState(true);

  useEffect(async () => {
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
    const res = await getCoverageAreas();
    setCoverageArea(res);
  }, []);
  
  const handleNameCheck = async (e) => {
    const res = await uniqueShopName(e.target.value)
    setIsUnique(res)
  }

  const pickup_type = [
    { label: "Select One", value: "" },
    { label: "Electronics", value: "Electronics" },
    { label: "Health & Beauty", value: "Health & Beauty" },
    { label: "Jewelry", value: "Jewelry" },
    { label: "Clothing", value: "Clothing" },
    { label: "Craft", value: "Craft" },
    { label: "Fashion Accessories", value: "Fashion Accessories" },
    { label: "Fragile", value: "Fragile" },
    { label: "Groceries", value: "Groceries" },
    { label: "Others", value: "Others" },
  ];

  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }
    const subCity = [...coverageArea.city[cityCode - 1]?.sub_city];
    setCity({ cityCode });
    setsubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    setAreaArr(null);
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];
    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
  };

  // ========== Cities =========
  const c_o =
    coverageArea != null
      ? coverageArea.city.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr.map((item, id) =>
          item.active ? (
            <Option key={id} value={item.id}>
              {item.name}
            </Option>
          ) : null
        )
      : null;

  const onFinish = async (values) => {
    const res = await createStore(values);
    if (res) {
      close();
      if (re === true) {

        shopFnc()
      }
    }
  };

  const onFinishFailed = (errorInfo) => {};
  const fullWidth = global.window.innerWidth
  return (
    <Drawer
      width={fullWidth}
      onClose={close}
      visible={status}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={close}>Cancel</Button>
        </Space>
      }
    >
      <h2 style={{ padding: "20px", fontWeight: "600", textAlign: "center" }}>
        {lan === "bangla" ? "নতুন স্টোর তৈরি করুন" : "Create a new shop"}
      </h2>
      <Form
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              onChange={(e)=>handleNameCheck(e)}
              label={ isUnique ? 
                lan === "bangla" ? "স্টোরের নাম" : "Store Name" 
                : lan === "bangla" ? <span style={{color: 'red'}}>স্টোরের নামটি পূর্বে ব্যবহার করা হয়ে গিয়েছে </span> : <span style={{color: 'red'}}>Store name is exist</span> }
              rules={[{ required: true, message: "Please enter user name" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              label={lan === "bangla" ? "ইমেইল এড্রেস" : "Email Address"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "ইমেইল এড্রেস অত্যাবশ্যকীয়"
                      : "Email Address is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="mobile_number"
              label={
                lan === "bangla"
                  ? "স্টোরের মোবাইল নাম্বার"
                  : "Store Mobile Number"
              }
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "স্টোরের মোবাইল নাম্বার অত্যাবশ্যকীয়"
                      : "Store Mobile is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="trade_license"
              label={
                lan === "bangla"
                  ? " স্টোরের ট্রেড লাইসেন্স (ঐচ্ছিক)"
                  : "Trade License (Optional)"
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* ================ City =============== */}
          <Col span={12}>
            <Form.Item
              name="city"
              label={lan === "bangla" ? " স্টোরের সিটি" : "Select City"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? " স্টোরের সিটি অত্যাবশ্যকীয়"
                      : "City  is required",
                },
              ]}
            >
              <Select
                placeholder={
                  lan === "bangla"
                    ? " স্টোরের সিটি সিলেক্ট করুন"
                    : "Select one city"
                }
                onChange={(e) => city_(e)}
              >
                {/* <Option value="-1">Jack Ma</Option> */}
                {c_o}
              </Select>
            </Form.Item>
          </Col>
          {/* ================ Sub City ============ */}
          {subCityArr ? (
            <Col span={12}>
              <Form.Item
                name="subCity"
                label={
                  lan === "bangla" ? " স্টোরের সাব-সিটি" : "Select Subcity"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? " স্টোরের সাব-সিটি অত্যাবশ্যকীয়"
                        : "Subcity is required",
                  },
                ]}
              >
                <Select
                  placeholder={
                    lan === "bangla"
                      ? " স্টোরের সাব-সিটি সিলেক্ট করুন"
                      : "Select one subcity"
                  }
                  onChange={(e) => sub_city(e)}
                >
                  {/* <Option value="-1">Select one</Option> */}
                  {s_o}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>

        <Row gutter={16}>
          {/* ============== Thana ============== */}
          {thanaArr ? (
            <Col span={12}>
              <Form.Item
                name="thana"
                label={
                  lan === "bangla"
                    ? " স্টোরের থানা সিলেক্ট করুন"
                    : "Select one thana"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? " স্টোরের থানা অত্যাবশ্যকীয়"
                        : "Thana is required",
                  },
                ]}
              >
                <Select
                  placeholder={
                    lan === "bangla"
                      ? " স্টোরের থানা সিলেক্ট করুন"
                      : "Select one subcity"
                  }
                  onChange={(e) => thana_(e)}
                >
                  {/* <Option value="-1">Select Thana</Option> */}
                  {t_o}
                </Select>
              </Form.Item>
            </Col>
          ) : null}

          {/* ============== Area ============= */}
          {areaArr ? (
            <Col span={12}>
              <Form.Item
                name="area"
                label={
                  lan === "bangla"
                    ? " স্টোরের এরিয়া সিলেক্ট করুন"
                    : "Select one area"
                }
                rules={[
                  {
                    required: true,
                    message:
                      lan === "bangla"
                        ? " স্টোরের এরিয়া অত্যাবশ্যকীয়"
                        : "Area is required",
                  },
                ]}
              >
                <Select
                  placeholder={
                    lan === "bangla"
                      ? " স্টোরের এরিয়া সিলেক্ট করুন"
                      : "Select one area"
                  }
                  onChange={(e) => area_(e)}
                >
                  {/* <Option value="-1">Select Area</Option> */}
                  {a_o}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="pickup_address"
              label={
                lan === "bangla" ? " পার্সেলের পিক-আপ এড্রেস" : "Pickup Address"
              }
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "পিক-আপ এড্রেস অত্যাবশ্যকীয়"
                      : "Pickup Address is required",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label={lan === "bangla" ? "স্টোরের এড্রেস" : "Store Address"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? "স্টোরের এড্রেস অত্যাবশ্যকীয়"
                      : "Address is required",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="pickup_type"
              label={lan === "bangla" ? " পিক আপ এর ধরন" : "Pickup Type"}
              rules={[
                {
                  required: true,
                  message:
                    lan === "bangla"
                      ? " পিক আপ এর ধরন অত্যাবশ্যকীয়"
                      : "Pickup type is required",
                },
              ]}
            >
              <Select
                placeholder={
                  lan === "bangla" ? " পিক আপ এর ধরন" : "Pickup Type"
                }
                onChange={(e) => city_(e)}
              >
                {pickup_type?.map((item, id) => (
                  <Option key={id} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>&nbsp;</Col>
          <Col span={24}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button disabled={!isUnique ? 'disabled' : null } htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default connect(null, { getCoverageAreas,uniqueShopName, createStore })(CreateStore);
